import { useMutation, useQueryClient } from "react-query";
import FetchClients from "../client";
import { BackpackServiceError, isBackpackResponseErrorBody } from "../errors";
import { PROJECT_KEY } from "./project";

const notes = { usePost, useDelete } as const;
export default notes;

async function addNote(projectId: number, note: string) {
  const { data, error } = await FetchClients.projects.POST(
    "/{project_id}/note",
    {
      params: {
        path: { project_id: projectId },
      },
      body: { body: note },
    }
  );

  if (data) return data;
  if (isBackpackResponseErrorBody(error)) {
    throw new BackpackServiceError(error);
  }
  throw new Error(error);
}
function usePost(siteId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ projectId, note }: { projectId: number; note: string }) =>
      addNote(projectId, note),
    onSettled: () => queryClient.invalidateQueries([PROJECT_KEY, siteId]),
  });
}

async function deleteNote(projectId: number, noteId: number) {
  const { error } = await FetchClients.projects.DELETE(
    "/{project_id}/note/{note_id}",
    {
      params: {
        path: { project_id: projectId, note_id: noteId },
      },
    }
  );
  if (error) {
    throw new Error(error);
  }
}

function useDelete(siteId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      projectId,
      noteId,
    }: {
      projectId: number;
      noteId: number;
    }) => deleteNote(projectId, noteId),
    onSettled: () => queryClient.invalidateQueries([PROJECT_KEY, siteId]),
  });
}
