import request from "../../request";

import { useOnboardingGuideContext } from "@src/backpack-console/components/OnboardingGuide";
import { Utility } from "@src/types";
import { snakeCase } from "lodash";
import { useMutation, useQuery } from "react-query";
import { recursiveCamelCaseCipher } from "@src/global_functions/postgrestApi";

const RESOURCE_URL = `/rest/onboarding_complete`;

const onboardingComplete = {
  useQueryAll,
  mutations: {
    usePost,
    useDelete,
  },
} as const;

export default onboardingComplete;

// snake_cased completeItem must exist in intel.onboarding_complete_enum or this call will fail

export const utilityCompleteItemToUtility: {
  [key in UtilityCompleteItem]: Utility;
} = {
  utilitiesElectricity: "electricity",
  utilitiesNaturalGas: "naturalGas",
  utilitiesDistrictCooling: "districtCooling",
  utilitiesDistrictHeating: "districtHeating",
  utilitiesWater: "water",
  utilitiesWaste: "waste",
} as const;

export type UtilityCompleteItem = `utilities${Capitalize<
  Exclude<Utility, "wasteWater">
>}`;
type CompleteItem =
  | "ratingsEnergyStar"
  | UtilityCompleteItem
  | "leasingDocument";

type OnboardingCompleteResponse = Array<{
  siteId: number;
  item: CompleteItem;
  optOut?: boolean;
}>;
function useQueryAll<T>(
  siteIds: number[],
  select?: (data: OnboardingCompleteResponse) => T
) {
  return useQuery({
    queryKey: [
      "multiple-site-onboarding-complete",
      siteIds
        .slice()
        .sort((a, b) => a - b)
        .join(),
    ],
    queryFn: () =>
      request
        .get(RESOURCE_URL)
        .query({
          site_id: `in.(${siteIds.join()})`,
        })
        .then(
          ({ body }) =>
            body.map(recursiveCamelCaseCipher) as OnboardingCompleteResponse
        ),
    select,
    enabled: !!siteIds.length,
  });
}

const postOnboardingComplete = (
  siteId: string | number,
  completeItem: CompleteItem,
  optOut: boolean
) => {
  return request
    .post(RESOURCE_URL)
    .send({ site_id: siteId, item: snakeCase(completeItem), opt_out: optOut });
};

function usePost(siteId: number) {
  const { refreshGuideData } = useOnboardingGuideContext();

  return useMutation({
    mutationFn: ({
      item,
      optOut = false,
    }: {
      item: CompleteItem;
      optOut?: boolean;
    }) => postOnboardingComplete(siteId, item, optOut),
    onSuccess: () => refreshGuideData(),
  });
}
const deleteOnboardingComplete = (
  siteId: string | number,
  completeItem: CompleteItem
) => {
  return request
    .delete(RESOURCE_URL)
    .query({ site_id: `eq.${siteId}`, item: `eq.${snakeCase(completeItem)}` });
};

function useDelete(siteId: number) {
  const { refreshGuideData } = useOnboardingGuideContext();

  return useMutation({
    mutationFn: (item: CompleteItem) => deleteOnboardingComplete(siteId, item),
    onSuccess: () => refreshGuideData(),
  });
}
