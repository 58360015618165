import FetchClients, { ClientOutput } from "../client";
import {
  useMutation,
  useQuery as useReactQuery,
  useQueryClient,
} from "react-query";
import { CamelCasedPropertiesDeep } from "type-fest";
import {
  recursiveCamelCaseCipher,
  recursiveSnakeCaseCipher,
} from "@src/global_functions/postgrestApi";
import { NotFoundError } from "./util";
import { PARKING_QUERY_KEYS } from "./detachedParking";
import { retryIfNot404 } from "@src/global_functions/fortyTwoApi/reactQueryUtils";

type SnakeCaseSurfaceLot = ClientOutput["tenantsAndSpaces"]["SurfaceLot"];
export type SurfaceLot = CamelCasedPropertiesDeep<SnakeCaseSurfaceLot>;

const surfaceLot = {
  useQuery,
  useQueryAll,
  mutations: { useDelete, usePut },
} as const;

export default surfaceLot;

const SURFACE_LOT_QUERY_KEY = "/surface_lot";

const getSurfaceLots = async (site_id: number) => {
  const { data, error } = await FetchClients.tenantsAndSpaces.GET(
    "/{site_id}/surface_lot",
    { params: { path: { site_id } } }
  );
  if (data) return recursiveCamelCaseCipher(data) as SurfaceLot[];
  throw new Error(error);
};

const _getSurfaceLot = async (site_id: number, surface_lot_id: number) => {
  const { data, error } = await FetchClients.tenantsAndSpaces.GET(
    "/{site_id}/surface_lot/{surface_lot_id}",
    {
      params: { path: { site_id, surface_lot_id } },
    }
  );
  if (data) return recursiveCamelCaseCipher(data) as SurfaceLot;
  throw new Error(error);
};

const getSurfaceLot = async (site_id: number, surface_lot_id?: number) => {
  if (!surface_lot_id) {
    const [_surfaceLot] = await getSurfaceLots(site_id);
    if (_surfaceLot) return _surfaceLot;
    throw new NotFoundError(`No surface lot set up for site ${site_id}`);
  }
  return _getSurfaceLot(site_id, surface_lot_id);
};

function useQuery(site_id: number, surface_lot_id?: number) {
  return useReactQuery(
    [SURFACE_LOT_QUERY_KEY, site_id, surface_lot_id],
    () => getSurfaceLot(site_id, surface_lot_id),
    {
      refetchOnWindowFocus: false,
      retry: retryIfNot404,
    }
  );
}

function useQueryAll(site_id: number) {
  return useReactQuery(
    [SURFACE_LOT_QUERY_KEY, site_id],
    () => getSurfaceLots(site_id),
    {
      refetchOnWindowFocus: false,
    }
  );
}

function usePut(
  site_id: number,
  { invalidateParkingQueries }: { invalidateParkingQueries?: boolean } = {}
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body: SurfaceLot) => {
      const { response, error } = await FetchClients.tenantsAndSpaces.PUT(
        "/{site_id}/surface_lot",
        {
          params: { path: { site_id } },
          body: recursiveSnakeCaseCipher(body),
        }
      );
      if (response.ok) return;
      throw new Error(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([SURFACE_LOT_QUERY_KEY, site_id]);
      if (invalidateParkingQueries)
        await queryClient.invalidateQueries([
          PARKING_QUERY_KEYS.parking,
          site_id,
        ]);
    },
  });
}

function useDelete(
  site_id: number,
  { invalidateParkingQueries }: { invalidateParkingQueries?: boolean } = {}
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (surface_lot_id: number) => {
      const { response, error } = await FetchClients.tenantsAndSpaces.DELETE(
        "/{site_id}/surface_lot/{surface_lot_id}",
        {
          params: { path: { site_id, surface_lot_id } },
        }
      );
      if (response.ok) return;
      throw new Error(error);
    },
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: [SURFACE_LOT_QUERY_KEY, site_id],
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([SURFACE_LOT_QUERY_KEY, site_id]);
      if (invalidateParkingQueries)
        await queryClient.invalidateQueries([
          PARKING_QUERY_KEYS.parking,
          site_id,
        ]);
    },
  });
}
