import FetchClients, { ClientOutput } from "../client";
import {
  useQuery as useReactQuery,
  useMutation,
  useQueryClient,
} from "react-query";
import {
  recursiveCamelCaseCipher,
  recursiveSnakeCaseCipher,
} from "@src/global_functions/postgrestApi";
import { CamelCasedPropertiesDeep } from "type-fest";
import backpackSdk from "../sdk";
import { tenantsAndSpacesKeys } from "@src/global_functions/fortyTwoApi/tenantsAndSpaces/queryKeyFactory";
import { SPACES_WITH_ESTAR_REQUIREMENT_KEY } from "@src/global_functions/fortyTwoApi/tenantsAndSpaces/hooks/useGetSpacesWithEnergyStarRequirements";
import { FLOORS_QUERY_KEY } from "./floors";
import request from "@src/global_functions/request";
import { VITE_TENANTS_AND_SPACES_BASE_URL } from "@src/env";

import { throwBackpackSuperagentResponseError } from "../errors";

const spaces = {
  useQueryAll,
  mutations: { useDelete, useDeleteAll, usePut, usePost, useBulkCsvPost },
} as const;

export default spaces;

type ApiSpecSpace =
  ClientOutput["tenantsAndSpaces"]["SpaceWithEnergyStarAttributesWithRequiredFields"];
type SnakeCaseSpace = Omit<ApiSpecSpace, "tenant_name"> & {
  tenant: ApiSpecSpace["tenant_name"];
};

type IncludeDirective = ClientOutput["tenantsAndSpaces"]["IncludeDirective"];
export type Space = CamelCasedPropertiesDeep<SnakeCaseSpace>;

const getSpaces = async (
  site_id: number,
  building_id?: number | null,
  includeDirective?: IncludeDirective
) => {
  if (typeof building_id !== "number") {
    return Promise.reject(new Error("Invalid Building ID"));
  }

  return FetchClients.tenantsAndSpaces
    .GET("/{site_id}/buildings/{building_id}/spaces", {
      params: {
        path: { site_id, building_id },
        query: { include: includeDirective },
      },
    })
    .then(({ data }) => data && (recursiveCamelCaseCipher(data) as Space[]));
};

// get all the spaces for all the buildings for a site
function useQueryAll(site_id: number, includeDirective?: IncludeDirective) {
  const { data: buildings, isLoading: buildingsLoading } =
    backpackSdk.tenantsAndSpaces.building.useQueryAll(site_id);
  const spacesQuery = useReactQuery({
    queryFn: () =>
      Promise.all(
        (buildings ?? []).map((building) =>
          getSpaces(site_id, building?.id, includeDirective)
        )
      ),
    queryKey: tenantsAndSpacesKeys.query(
      site_id,
      "space",
      SPACES_WITH_ESTAR_REQUIREMENT_KEY
    ),
    enabled: !!buildings?.length,
    select: (data) => data.flat(),
  });
  return {
    ...spacesQuery,
    isLoading: buildingsLoading || spacesQuery.isLoading,
  };
}

const deleteSpace = async (
  site_id: number,
  building_id?: number | null,
  space_id?: number
) => {
  if (typeof building_id !== "number" || typeof space_id !== "number")
    throw new Error("Invalid building or space id.");
  const { response, error } = await FetchClients.tenantsAndSpaces.DELETE(
    "/{site_id}/buildings/{building_id}/spaces/{space_id}",
    {
      params: {
        path: {
          site_id,
          building_id,
          space_id,
        },
      },
    }
  );
  if (response.ok) return;
  throw new Error(error);
};

function useDelete(
  site_id: number,
  building_id?: number | null,
  { invalidateQueries }: { invalidateQueries?: boolean } = {
    invalidateQueries: false,
  }
) {
  const queryClient = useQueryClient();
  return useMutation(
    (space_id: number) => deleteSpace(site_id, building_id, space_id),
    {
      onSuccess: async () => {
        if (invalidateQueries) {
          await queryClient.invalidateQueries([
            FLOORS_QUERY_KEY,
            site_id,
            building_id,
          ]);
        }
      },
    }
  );
}

const deleteAllSpaces = async (
  site_id: number,
  building_id?: number | null
) => {
  if (typeof building_id !== "number")
    throw new Error("Invalid building or space id.");
  const { response, error } = await FetchClients.tenantsAndSpaces.DELETE(
    "/{site_id}/buildings/{building_id}/spaces",
    {
      params: {
        path: {
          site_id,
          building_id,
        },
      },
    }
  );
  if (response.ok) return;
  throw new Error(error);
};

function useDeleteAll(
  siteId: number,
  buildingId?: number | null,
  { invalidateQueries }: { invalidateQueries?: boolean } = {
    invalidateQueries: false,
  }
) {
  const queryClient = useQueryClient();
  return useMutation(() => deleteAllSpaces(siteId, buildingId), {
    onSuccess: async () => {
      if (invalidateQueries) {
        await queryClient.invalidateQueries([
          FLOORS_QUERY_KEY,
          siteId,
          buildingId,
        ]);
      }
    },
  });
}

function usePut(site_id: number, building_id?: number | null) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body: Space) => {
      if (typeof building_id !== "number" || typeof body.id !== "number")
        throw new Error("Invalid building or space id.");
      const { response, error } = await FetchClients.tenantsAndSpaces.PUT(
        "/{site_id}/buildings/{building_id}/spaces/{space_id}",
        {
          params: { path: { site_id, building_id, space_id: body.id } },
          body: recursiveSnakeCaseCipher(body),
        }
      );
      if (response.ok) return;
      throw new Error(error);
    },
    onSuccess: () =>
      queryClient.invalidateQueries([FLOORS_QUERY_KEY, site_id, building_id]),
  });
}

function usePost(site_id: number, building_id?: number | null) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body: Space) => {
      if (typeof building_id !== "number")
        throw new Error("Invalid building id.");
      const { response, error } = await FetchClients.tenantsAndSpaces.POST(
        "/{site_id}/buildings/{building_id}/spaces",
        {
          params: { path: { site_id, building_id } },
          body: recursiveSnakeCaseCipher(body),
        }
      );
      if (response.ok) return;
      throw new Error(error);
    },
    onSuccess: () =>
      queryClient.invalidateQueries([FLOORS_QUERY_KEY, site_id, building_id]),
  });
}

function useBulkCsvPost(site_id: number, building_id?: number | null) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body: File) => {
      if (typeof building_id !== "number")
        throw new Error("Invalid building id.");

      try {
        await request
          .post(
            `${VITE_TENANTS_AND_SPACES_BASE_URL}/sites/${site_id}/buildings/${building_id}/spaces/csv`
          )
          .send(body);
      } catch (e) {
        throw throwBackpackSuperagentResponseError(e);
      }
    },
    onSuccess: () =>
      queryClient.invalidateQueries([FLOORS_QUERY_KEY, site_id, building_id]),
  });
}
