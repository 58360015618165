import FetchClients, { ClientOutput } from "../client";
import { useQuery as useReactQuery } from "react-query";
import { CamelCasedPropertiesDeep } from "type-fest";
import { recursiveCamelCaseCipher } from "@src/global_functions/postgrestApi";
import { FloorWithSpaces } from "./util";
import * as R from "ramda";

type SnakeCaseFloorsResponse =
  ClientOutput["tenantsAndSpaces"]["FloorsHierarchy"];
type FloorsResponse = CamelCasedPropertiesDeep<
  Omit<SnakeCaseFloorsResponse, "floors"> & {
    floors: FloorWithSpaces[];
  }
>;

type IncludeDirective = ClientOutput["tenantsAndSpaces"]["IncludeDirective"];

const floors = { useQuery, useQueryAll } as const;

export default floors;

export const FLOORS_QUERY_KEY = "/floors";

const getBuildingFloors = async (
  site_id: number,
  building_id?: number | null,
  { include }: { include?: IncludeDirective } = {}
) => {
  if (typeof building_id !== "number") throw new Error("Invalid building id");
  const { data, error } = await FetchClients.tenantsAndSpaces.GET(
    "/{site_id}/buildings/{building_id}/floors",
    {
      params: {
        path: { site_id, building_id },
        ...(include ? { query: { include } } : {}),
      },
    }
  );
  if (data) return recursiveCamelCaseCipher(data) as FloorsResponse;
  throw new Error(error);
};

function useQuery(
  site_id: number,
  building_id?: number | null,
  _opts?: { enabled?: boolean; include?: IncludeDirective }
) {
  const { include, ...opts } = _opts ?? {};
  return useReactQuery(
    [FLOORS_QUERY_KEY, site_id, building_id, include],
    () => getBuildingFloors(site_id, building_id, { include }),
    {
      refetchOnWindowFocus: false,
      ...opts,
    }
  );
}

function useQueryAll(site_id: number, building_ids: number[]) {
  return useReactQuery(
    [FLOORS_QUERY_KEY, site_id, R.sort((a, b) => a - b, building_ids).join()],
    () => Promise.all(building_ids.map((id) => getBuildingFloors(site_id, id))),
    {
      refetchOnWindowFocus: false,
      enabled: !!building_ids.length,
    }
  );
}
