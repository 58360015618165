import classNames from "classnames";
import {
  ComponentPropsWithoutRef,
  useEffect,
  forwardRef,
  ForwardedRef,
} from "react";
import useAsync from "../../../global_functions/useAsync";
import Alerts, { AlertEnum } from "../../base/dialogs/Alerts/Alerts";
import Button, { ButtonProps } from "../../base/buttons/Button/Button";
import Icon from "../../base/icons/Icon/Icon";
import { parseHtmlErrorMessage } from "@src/global_functions/backpackSdk/errors";

type AsyncButtonProps = ButtonProps<"button"> & {
  onClick: (e: React.MouseEvent<HTMLElement>) => Promise<void>;
  successMessage?: React.ReactNode;
  errorMessageIcon?: boolean;
  alertRenderLocation?: "topRight" | "bottomRight" | "left";
  containerProps?: ComponentPropsWithoutRef<"div">;
};

function AsyncButton(
  {
    onClick,
    successMessage,
    containerProps,
    errorMessageIcon,
    alertRenderLocation = "topRight",
    ...props
  }: AsyncButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const [state, action] = useAsync(
    async (e: React.MouseEvent<HTMLElement>) => await onClick(e),
    [onClick]
  );

  // Add listener to remove success message on document click
  const { success, setState } = state;
  useEffect(() => {
    if (success) {
      const listener = () => {
        setState((prev) => ({ ...prev, success: false }));
      };
      document.addEventListener("click", listener);
      return () => document.removeEventListener("click", listener);
    }
  }, [success, setState]);

  return (
    <div className="relative" {...containerProps}>
      <Button
        {...props}
        ref={ref}
        loading={state.loading}
        onClick={(e: React.MouseEvent<HTMLElement>) =>
          !state.loading && !props.disabled && action(e)
        }
      />
      {state.error && (
        <div
          className={classNames("absolute z-10", {
            "top-[calc(100%+2px)]": alertRenderLocation === "bottomRight",
            "bottom-[calc(100%+2px)]": alertRenderLocation === "topRight",
            "right-[calc(100%+8px)] top-0 ": alertRenderLocation === "left",
          })}
        >
          <Alerts
            message={
              <span className="flex items-center gap-2">
                {errorMessageIcon ? (
                  <Icon
                    name="warning"
                    className="shrink-0"
                    size="small"
                    color="negative"
                  />
                ) : null}
                {parseHtmlErrorMessage(
                  typeof state.error === "string"
                    ? state.error
                    : state.error.message
                )}
              </span>
            }
            status={AlertEnum.Error}
          />{" "}
        </div>
      )}
      {state.success && successMessage && (
        <div
          className={classNames("absolute bg-white", {
            "top-[calc(100%+8px)]": alertRenderLocation === "topRight",
            "bottom-[calc(100%+8px)]": alertRenderLocation === "bottomRight",
            "right-[calc(100%+8px)] top-0 ": alertRenderLocation === "left",
          })}
        >
          <Alerts status={AlertEnum.Success} message={successMessage} />
        </div>
      )}
    </div>
  );
}

export default forwardRef(AsyncButton);
