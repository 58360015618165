import { useQuery as useReactQuery } from "react-query";

import { recursiveCamelCaseCipher } from "@src/global_functions/postgrestApi";
import request from "@src/global_functions/request";
import { BuildingType } from "@src/types";

const buildingTypes = {
  useQuery,
} as const;

export default buildingTypes;

const BUILDING_TYPES_KEY = "postgrest/buildingTypes";

const getBuildingTypes = (): Promise<BuildingType[]> =>
  request
    .get(`/rest/v_energy_star_property`)
    .then(({ body }) => recursiveCamelCaseCipher(body));

function useQuery() {
  return useReactQuery({
    queryKey: [BUILDING_TYPES_KEY],
    queryFn: getBuildingTypes,
    refetchOnWindowFocus: false,
  });
}
