import { useQuery, useQueryClient, useMutation } from "react-query";
import FetchClients, { ClientOutput } from "../client";
import { PREVIEW_BASE_KEY } from "@src/backpack-console/pages/SiteDashboard/Projects/LegacyProjects/ProjectsAttachments";
import { PROJECT_KEY } from "./project";
const rewards = {
  useQueryAllVendors,
  mutations: {
    usePost,
    usePatch,
    useDelete,
    useDeleteReceipts,
  },
} as const;

export type Reward = ClientOutput["projects"]["Reward"];

function useQueryAllVendors() {
  return useQuery(["all-vendors"], async () => {
    const { data, error } = await FetchClients.projects.GET(
      "/rewards/vendors",
      {}
    );
    if (data) return data;
    throw new Error(error);
  });
}

function usePost(project_id: number, site_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      body: Pick<Reward, "receipt_ids" | "payback_method"> & {
        vendor_id: Reward["vendor"]["id"];
      }
    ) => {
      const { data, error } = await FetchClients.projects.POST(
        "/{project_id}/rewards",
        {
          params: {
            path: { project_id },
          },
          body,
        }
      );
      if (data) return data;
      throw new Error(error);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [PROJECT_KEY, site_id],
        }),
        queryClient.invalidateQueries({
          queryKey: [PREVIEW_BASE_KEY, "rewards", project_id],
        }),
      ]),

    onError: (e) => {
      throw new Error(
        `Unable to create new reward for project ${project_id}, site ${site_id}
        ${(e as Error)?.message || "Unknown Error"}`
      );
    },
  });
}

function usePatch(project_id: number, site_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      _body: Omit<Reward, "vendor"> & { vendor_id: Reward["vendor"]["id"] }
    ) => {
      const { id, ...body } = _body;
      const { data, error } = await FetchClients.projects.PATCH(
        "/{project_id}/rewards/{reward_id}",
        {
          params: {
            path: { project_id, reward_id: id },
          },
          body,
        }
      );
      if (data) return data;
      throw new Error(error);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [PROJECT_KEY, site_id],
        }),
        queryClient.invalidateQueries({
          queryKey: [PREVIEW_BASE_KEY, "rewards", project_id],
        }),
      ]),
    onError: (e) => {
      throw new Error(
        `Unable to update reward for project ${project_id}, site ${site_id}
        ${(e as Error)?.message || "Unknown Error"}`
      );
    },
  });
}

function useDelete(project_id: number, site_id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: number) => {
      const { data, error } = await FetchClients.projects.DELETE(
        "/{project_id}/rewards/{reward_id}",
        {
          params: {
            path: { project_id, reward_id: id },
          },
        }
      );
      if (data) return data;
      throw new Error(error);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [PROJECT_KEY, site_id],
        }),
        queryClient.invalidateQueries({
          queryKey: [PREVIEW_BASE_KEY, "rewards", project_id],
        }),
      ]),
    onError: (e) => {
      throw new Error(
        `Unable to delete reward for project ${project_id}, site ${site_id}
        ${(e as Error)?.message || "Unknown Error"}`
      );
    },
  });
}
// service is in D3, but break from shared functionality with projects attachments
function useDeleteReceipts(site_id: number, project_id?: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (rewardReceiptIds: number[]) => {
      const promises = rewardReceiptIds.map((reward_receipt_id) =>
        FetchClients.d3.DELETE("/reward-receipt/{reward_receipt_id}", {
          params: {
            path: { reward_receipt_id },
          },
        })
      );
      const results = await Promise.all(promises);
      results.forEach(({ response }) => {
        if (!response.ok) {
          throw new Error(
            `Failed to delete an unsubmitted receipt: ${response.statusText}`
          );
        }
      });
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: [PROJECT_KEY, site_id],
        }),
        queryClient.invalidateQueries({
          queryKey: [PREVIEW_BASE_KEY, "rewards", project_id],
        }),
      ]),
    onError: (e) => {
      throw new Error(
        `Unable to clean up unsubmitted receipt for project ${project_id}, site ${site_id}
        ${(e as Error)?.message || "Unknown Error"}`
      );
    },
  });
}

export default rewards;
