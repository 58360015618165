import request from "../request";

import {
  camelCaseCipher,
  snakeCaseCipher,
  recursiveCamelCaseCipher,
  formatDataObj,
  bugsnagPostgrestErrorHandler,
  bugsnagGeneralErrorHandler,
} from "./common";

import type { User } from "../../types";

import { isDev } from "../../env";

export const updateUser = (
  userId: number,
  updatedProps: Partial<User>,
  sendInvite?: boolean
): Promise<void> =>
  request
    .patch("/rest/users")
    .query({ id: `eq.${userId}` })
    .send(formatDataObj(updatedProps, snakeCaseCipher, { allowNulls: true }))
    .then(async () => {
      if (sendInvite) {
        await sendUserInvitation(userId);
      }
    })
    .catch(bugsnagPostgrestErrorHandler);

export const sendUserInvitation = (userId: number): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'SuperAgentRequest' is not assignable to type 'Promise<void>'.
  request.post("/api/user_invites").send({ user_id: userId });

export const queryCurrentUser = (): Promise<User | null | undefined> =>
  request
    .get(`/rest/current_user`)
    .set("Accept", "application/vnd.pgrst.object+json")
    .then(({ body }) => ({
      ...formatDataObj(body, camelCaseCipher),
      imageURL: body.image_key
        ? `https://bractlet-public${
            isDev ? "-dev" : ""
          }.s3.amazonaws.com/intel/user_images/${body.image_key}.jpeg`
        : null,
      readOnly: body.user_permissions === "read_only",
      siteUserAllowLists: recursiveCamelCaseCipher(body.site_user_allow_lists),
    }))
    .catch(bugsnagPostgrestErrorHandler);

export const uploadUserImage = async (
  userId: string | number,
  imageBlob: Blob
): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'void | Response' is not assignable to type 'void'.
  request
    .post("/uploads")
    .send({ type: "user_image", id: userId })
    .then(({ body }) =>
      request
        .put(body.url) // received pre-signed S3 url from backend to upload to
        .set("Content-Type", imageBlob.type)
        .send(imageBlob)
    )
    .then(() =>
      request
        .post("/upload_completions")
        .send({ type: "user_image", id: userId })
    )
    .catch(bugsnagGeneralErrorHandler);
