import * as React from "react";

export const DocumentTitle = ({ title }: { title: string }) => {
  React.useEffect(() => {
    const previousTitle = document.title;
    document.title = `${title} | Backpack`;
    return () => {
      document.title = previousTitle;
    };
  }, [title]);

  return <></>;
};
