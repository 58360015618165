import * as React from "react";
import ReactDOM from "react-dom";
import { Transition } from "react-transition-group";
import classnames from "classnames";

import { debounce } from "lodash";

import styles from "./Popover.module.scss";

function getOffsetTop(rect: ClientRect | any, vertical: number | string) {
  let offset = 0;

  if (typeof vertical === "number") {
    offset = vertical;
  } else if (vertical === "center") {
    offset = rect.height / 2;
  } else if (vertical === "bottom") {
    offset = rect.height;
  }

  return offset;
}

function getOffsetLeft(rect: ClientRect | any, horizontal: number | string) {
  let offset = 0;

  if (typeof horizontal === "number") {
    offset = horizontal;
  } else if (horizontal === "center") {
    offset = rect.width / 2;
  } else if (horizontal === "right") {
    offset = rect.width;
  }

  return offset;
}

function getTransformOriginValue(transformOrigin: {
  horizontal: any | number;
  vertical: any | number;
}) {
  return [transformOrigin.horizontal, transformOrigin.vertical]
    .map((n) => (typeof n === "number" ? `${n}px` : n))
    .join(" ");
}

function resolveAnchorEl(anchorEl: Element | (() => Element)) {
  return typeof anchorEl === "function" ? anchorEl() : anchorEl;
}

type Props = {
  anchorEl: Element | (() => Element);
  anchorOrigin?: {
    horizontal: "center" | "left" | "right" | number;
    vertical: "bottom" | "center" | "top" | number;
  };
  anchorPosition?: {
    left: number;
    top: number;
  };
  anchorReference?: "anchorEl" | "anchorPosition" | "none";
  children: React.ReactNode;
  marginThreshold?: number;
  open: boolean;
  transformOrigin?: {
    horizontal: "center" | "left" | "right";
    vertical: "bottom" | "center" | "top";
  };
  PaperProps?: any;
  className?: string;
  onClose: () => void;
};

const Popover = React.forwardRef<Props, any>(function Popover(props, ref) {
  const {
    anchorEl,
    anchorOrigin = {
      vertical: "top",
      horizontal: "left",
    },
    anchorPosition,
    anchorReference = "anchorEl",
    children,
    marginThreshold = 16,
    open,
    transformOrigin = {
      vertical: "top",
      horizontal: "left",
    },
    PaperProps = {},
    className,
    onClose,
  } = props;
  const paperRef = React.useRef();

  // Returns the top/left offset of the position
  const getAnchorOffset = React.useCallback(() => {
    if (anchorReference === "anchorPosition") {
      return anchorPosition;
    }

    const resolvedAnchorEl = resolveAnchorEl(anchorEl);

    // If an anchor element wasn't provided, just use the parent body element of this Popover
    const anchorElement =
      resolvedAnchorEl && resolvedAnchorEl.nodeType === 1
        ? resolvedAnchorEl
        : document.body;
    const anchorRect = anchorElement.getBoundingClientRect();

    return {
      top: anchorRect.top + getOffsetTop(anchorRect, anchorOrigin.vertical),
      left:
        anchorRect.left + getOffsetLeft(anchorRect, anchorOrigin.horizontal),
    };
  }, [
    anchorEl,
    anchorOrigin.horizontal,
    anchorOrigin.vertical,
    anchorPosition,
    anchorReference,
  ]);

  // Returns the base transform origin using the element
  const getTransformOrigin = React.useCallback(
    (elemRect) => {
      return {
        vertical: getOffsetTop(elemRect, transformOrigin.vertical),
        horizontal: getOffsetLeft(elemRect, transformOrigin.horizontal),
      };
    },
    [transformOrigin.horizontal, transformOrigin.vertical]
  );

  const getPositioningStyle = React.useCallback(
    (element) => {
      const elemRect = {
        width: element.offsetWidth,
        height: element.offsetHeight,
      } as const;

      // Get the transform origin point on the element itself
      const elemTransformOrigin = getTransformOrigin(elemRect);

      if (anchorReference === "none") {
        return {
          top: null,
          left: null,
          transformOrigin: getTransformOriginValue(elemTransformOrigin),
        };
      }

      // Get the offset of the anchoring element
      const anchorOffset = getAnchorOffset();

      // Calculate element positioning
      let top =
        (anchorOffset ? anchorOffset.top : 0) - elemTransformOrigin.vertical;
      let left =
        (anchorOffset ? anchorOffset.left : 0) - elemTransformOrigin.horizontal;
      const bottom = top + elemRect.height;
      const right = left + elemRect.width;

      // Use the parent window of the anchorEl if provided
      const containerWindow = window;

      // Window thresholds taking required margin into account
      const heightThreshold = containerWindow.innerHeight - marginThreshold;
      const widthThreshold = containerWindow.innerWidth - marginThreshold;

      // Check if the vertical axis needs shifting
      if (top < marginThreshold) {
        const diff = top - marginThreshold;
        top -= diff;
        elemTransformOrigin.vertical += diff;
      } else if (bottom > heightThreshold) {
        const diff = bottom - heightThreshold;
        top -= diff;
        elemTransformOrigin.vertical += diff;
      }

      // Check if the horizontal axis needs shifting
      if (left < marginThreshold) {
        const diff = left - marginThreshold;
        left -= diff;
        elemTransformOrigin.horizontal += diff;
      } else if (right > widthThreshold) {
        const diff = right - widthThreshold;
        left -= diff;
        elemTransformOrigin.horizontal += diff;
      }

      return {
        top: `${Math.round(top)}px`,
        left: `${Math.round(left)}px`,
        transformOrigin: getTransformOriginValue(elemTransformOrigin),
      };
    },
    [anchorReference, getAnchorOffset, getTransformOrigin, marginThreshold]
  );

  const setPositioningStyles = React.useCallback(() => {
    const element = paperRef.current;

    if (!element) {
      return;
    }

    const positioning = getPositioningStyle(element);

    if (positioning.top !== null) {
      // @ts-expect-error - TS2339 - Property 'style' does not exist on type 'never'.
      element.style.top = positioning.top;
    }
    if (positioning.left !== null) {
      // @ts-expect-error - TS2339 - Property 'style' does not exist on type 'never'.
      element.style.left = positioning.left;
    }
    // @ts-expect-error - TS2339 - Property 'style' does not exist on type 'never'.
    element.style.transformOrigin = positioning.transformOrigin;
  }, [getPositioningStyle]);

  React.useEffect(() => {
    if (open) {
      setPositioningStyles();
    }
  });
  const handleResize = React.useMemo(
    () =>
      debounce(() => {
        setPositioningStyles();
      }, 300),
    [setPositioningStyles]
  );

  React.useEffect(() => {
    if (!open) {
      return undefined;
    }

    window.addEventListener("resize", handleResize);
    return () => {
      handleResize.cancel();
      window.removeEventListener("resize", handleResize);
    };
  }, [open, handleResize]);

  return (
    open &&
    ReactDOM.createPortal(
      <div
        className={classnames(styles.popover_container, className)}
        // @ts-expect-error - TS2322 - Type 'ForwardedRef<Props>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'.
        ref={ref}
      >
        <div className={styles.popover_backdrop} onClick={onClose} />
        <Transition appear={true} in={props.open} timeout={250}>
          {(state) => (
            <div
              {...PaperProps}
              className={classnames(styles.popover_paper, PaperProps.className)}
              ref={paperRef}
            >
              {children}
            </div>
          )}
        </Transition>
      </div>,
      // @ts-expect-error - TS2345 - Argument of type 'HTMLElement | null' is not assignable to parameter of type 'Element | DocumentFragment'.
      document.getElementById("root")
    )
  );
});

export default Popover;
