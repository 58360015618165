import * as React from "react";
import * as R from "ramda";
import type {
  SelectOption,
  UnitConversions,
  UtilityUnit,
  ArcUtilityUnit,
  EnergyStarUtilityUnit,
  EnergyStarWasteUnit,
} from "../types";

const unitsMapping: Array<{
  dbName: string;
  valinorUnit: UtilityUnit;
  arcNativeUnit: ArcUtilityUnit | null | undefined;
  energyStarUnit:
    | EnergyStarUtilityUnit
    | EnergyStarWasteUnit
    | null
    | undefined;
}> = [
  {
    dbName: "therms",
    valinorUnit: "thm",
    arcNativeUnit: "therms",
    energyStarUnit: "therms",
  },
  {
    dbName: "kwhs",
    valinorUnit: "kWh",
    arcNativeUnit: "kWh",
    energyStarUnit: "kWh (thousand Watt-hours)",
  },
  {
    dbName: "mbtus",
    valinorUnit: "MBTU",
    arcNativeUnit: "MBtu",
    energyStarUnit: "MBtu (million Btu)",
  },
  {
    dbName: "kbtus",
    valinorUnit: "kBTU",
    arcNativeUnit: "kBtu",
    energyStarUnit: "kBtu (thousand Btu)",
  },
  {
    dbName: "ccfs",
    valinorUnit: "CCF",
    arcNativeUnit: "ccf",
    energyStarUnit: "ccf (hundred cubic feet)",
  },
  {
    dbName: "kcf",
    valinorUnit: "KCF",
    arcNativeUnit: "kcf",
    energyStarUnit: "kcf (thousand cubic feet)",
  },
  {
    dbName: "ton_hours",
    valinorUnit: "ton-hour",
    arcNativeUnit: "ton hours",
    energyStarUnit: "ton hours",
  },
  {
    dbName: "gallons",
    valinorUnit: "gal",
    arcNativeUnit: "gal",
    energyStarUnit: "Gallons (US)",
  },
  {
    dbName: "cGals",
    valinorUnit: "cGal",
    arcNativeUnit: "cGal (US)",
    energyStarUnit: "cGal (hundred gallons) (US)",
  },
  {
    dbName: "kGals",
    valinorUnit: "kGal",
    arcNativeUnit: "kGal",
    energyStarUnit: "KGal (thousand gallons) (US)",
  },
  {
    dbName: "giga_joules",
    valinorUnit: "GJ",
    arcNativeUnit: null,
    energyStarUnit: null,
  },
  {
    dbName: "joules",
    valinorUnit: "J",
    arcNativeUnit: null,
    energyStarUnit: null,
  },
  {
    dbName: "cubic_yards",
    valinorUnit: "yd3",
    arcNativeUnit: null,
    energyStarUnit: "Cubic yards",
  },
  {
    dbName: "tons",
    valinorUnit: "ton",
    arcNativeUnit: "US tons",
    energyStarUnit: "tons",
  },
  {
    dbName: "thousand_pounds_steam",
    valinorUnit: "kLb",
    arcNativeUnit: null,
    energyStarUnit: "KLbs. (thousand pounds)",
  },
  {
    dbName: "cubic_meters",
    valinorUnit: "m3",
    arcNativeUnit: null,
    energyStarUnit: null,
  },
  {
    dbName: "tonnes",
    valinorUnit: "tonnes",
    arcNativeUnit: null,
    energyStarUnit: null,
  },
  {
    dbName: "mcf",
    valinorUnit: "MCF (million cubic feet)",
    arcNativeUnit: "mcf",
    energyStarUnit: "MCF(million cubic feet)",
  },
];

const createUnitCipher = (keyPropName: string, valuePropName: string) =>
  R.fromPairs(
    // @ts-expect-error - TS2345 - Argument of type 'any[][]' is not assignable to parameter of type 'readonly (readonly [string, unknown])[] | readonly (readonly [number, unknown])[]'.
    unitsMapping
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ dbName: string; valinorUnit: UtilityUnit; arcNativeUnit: ArcUtilityUnit | null | undefined; energyStarUnit: EnergyStarUtilityUnit | EnergyStarWasteUnit | null | undefined; }'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ dbName: string; valinorUnit: UtilityUnit; arcNativeUnit: ArcUtilityUnit | null | undefined; energyStarUnit: EnergyStarUtilityUnit | EnergyStarWasteUnit | null | undefined; }'.
      .map((obj) => [obj[keyPropName], obj[valuePropName]])
      // @ts-expect-error - TS2769 - No overload matches this call.
      .filter(([key, value]: [any, any]) => key != null && value != null)
  );

// @ts-expect-error - TS2322 - Type '{ [index: string]: unknown; }' is not assignable to type '{ [key: string]: UtilityUnit; }'.
export const inboundUnitCipher: {
  [key: string]: UtilityUnit;
} = createUnitCipher("dbName", "valinorUnit");

export const outboundUnitEncoder: Partial<Record<UtilityUnit, string>> =
  createUnitCipher("valinorUnit", "dbName");

export const outboundArcUnitEncoder: Partial<
  Record<UtilityUnit, ArcUtilityUnit>
> = createUnitCipher("valinorUnit", "arcNativeUnit");

export const inboundArcUnitCipher: Partial<
  Record<ArcUtilityUnit, UtilityUnit>
> = createUnitCipher("arcNativeUnit", "valinorUnit");

export const inboundEnergyStarUnitCipher: Partial<
  Record<EnergyStarUtilityUnit | EnergyStarWasteUnit, UtilityUnit>
> = createUnitCipher("energyStarUnit", "valinorUnit");

export type UnitsContextType = {
  unitConversions: UnitConversions;
  getConversionFactor: (
    arg1: number | string | UtilityUnit,
    arg2: number | string | UtilityUnit
  ) => number | null | undefined;
  unitsMap: Partial<
    Record<
      string | number,
      {
        id: number;
        key: string;
        label: UtilityUnit;
      }
    >
  >;
  getUnitOptions: (units: Array<UtilityUnit>) => Array<SelectOption<number>>;
  getUnitIdFromUnit: (arg1: UtilityUnit) => number;
};

// @ts-expect-error - TS2322 - Type 'Context<{ unitConversions: {}; getConversionFactor: () => null; unitsMap: {}; getUnitOptions: (units: UtilityUnit[]) => never[]; getUnitIdFromUnit: (unit: UtilityUnit) => number; }>' is not assignable to type 'Context<UnitsContextType>'.
const UnitsContext: React.Context<UnitsContextType> = React.createContext({
  unitConversions: {},
  getConversionFactor: () => null,
  unitsMap: {},
  getUnitOptions: (units) => [],
  getUnitIdFromUnit: (unit) => -1,
});

export const useUnits = () => {
  const context = React.useContext(UnitsContext);
  const isLoading =
    Object.keys(context.unitConversions).length === 0 ||
    Object.keys(context.unitsMap).length === 0;

  return {
    ...context,
    isLoading,
  };
};

export default UnitsContext;
