import { Truthy } from "lodash";
type DownloadFileInput =
  | {
      url: string;
      filename: string;
    }
  | {
      blob: Blob;
      filename: string;
    };

export const downloadFile = (input: DownloadFileInput) => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  // @ts-expect-error - TS2540 - Cannot assign to 'style' because it is a read-only property.
  a.style = "display: none";
  // @ts-expect-error - TS2339 - Property 'url' does not exist on type 'DownloadFileInput'. | TS2339 - Property 'blob' does not exist on type 'DownloadFileInput'.
  const url = input.url || window.URL.createObjectURL(input.blob);

  a.href = url;
  a.download = input.filename;
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 0);
};
export type DownloadFileFn = typeof downloadFile;

export const downloadFileByUrl = (url: string) => {
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", "");
  a.setAttribute("target", "_self");
  a.click();
};

export const googleMapsAPIKey = "AIzaSyDbumC8gzfJgvUAZojb7llL3WgfeQkLWWE";

export const rgba = (hex: string | null | undefined, opacity: number) => {
  if (!hex) return hex;
  const value = Number(`0x${hex.replace("#", "")}`);

  const r = (value >> 16) & 255;
  const g = (value >> 8) & 255;
  const b = value & 255;

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export function rgbToHex(r: number, g: number, b: number) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function hexToRGB(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgba(${r}, ${g}, ${b})`;
  }
}

export const getTitleSegments = (name: string | undefined, section: string) => {
  return name ? `${name} | ${section}` : `${section}`;
};

// Joins paths with leading or trailing slashes while avoiding `//`
// Workaround react router v5 issue: https://github.com/remix-run/react-router/issues/4841
export function joinPaths(...segments: string[]) {
  return segments.join("/").replace(/\/+/g, "/");
}

// typesafe way to filter falsy values out of an array
export function truthy<T>(value: T): value is Truthy<T> {
  return !!value;
}

// This function must be called from a user activation event (ie an onclick event)
export const openFileBrowser = (
  acceptedFileTypes: string,
  onSelect: (files: File[]) => void,
  multiple = false
) => {
  const inputElement = document.createElement("input");
  // Hide element and append to body (required to run on iOS safari)
  inputElement.style.display = "none";
  document.body.appendChild(inputElement);
  inputElement.type = "file";
  inputElement.dataset.testid = "file-browser-input";

  if (acceptedFileTypes !== "*") inputElement.accept = acceptedFileTypes;
  inputElement.multiple = multiple;
  inputElement.addEventListener("change", (e) => {
    const inputEl = e.target as HTMLInputElement;
    const files = inputEl.files ? Array.from(inputEl.files) : [];
    onSelect(files);
    document.body.removeChild(inputElement);
  });

  // dispatch a click event to open the file dialog
  inputElement.dispatchEvent(new MouseEvent("click"));
};

export function getObjectKeys<T extends object>(obj: T) {
  return Object.keys(obj) as Array<Extract<keyof T, string>>;
}

export function getObjectEntries<T extends object>(obj: T) {
  return Object.entries(obj) as Array<
    [Extract<keyof T, string>, T[Extract<keyof T, string>]]
  >;
}

// Basically R.groupBy but with good Typing...
export function groupBy<T, K extends keyof T>(
  array: T[],
  key: K
): Record<string, T[]> {
  return array.reduce((result, currentItem) => {
    const keyValue = currentItem[key];

    const keyValueStr = String(keyValue);

    if (!result[keyValueStr]) {
      result[keyValueStr] = [];
    }

    result[keyValueStr]!.push(currentItem);

    return result;
  }, {} as Record<string, T[]>);
}

export function median(arr: number[]): number | undefined {
  if (!arr.length) return undefined;
  const s = [...arr].sort((a, b) => a - b);
  const mid = Math.floor(s.length / 2);
  return s.length % 2 ? s[mid]! : (s[mid - 1]! + s[mid]!) / 2;
}
