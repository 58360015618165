import { snakeCase } from "lodash";
import React from "react";
import { useQuery as useReactQuery } from "react-query";
import { VITE_SITE_SERVICE_BASE_URL } from "../../../env";
import { recursiveCamelCaseCipher } from "../../postgrestApi";
import request from "../../request";
import { EquipmentSummary, EquipmentType } from "./types";

const portfolio = {
  useQueryAccount: useQuery,
};

export default portfolio;

export const PORTFOLIO_EQUIPMENT_QUERY_KEY = "portfolio-equipment";

export type PortfolioEquipmentFilterParams = Readonly<{
  sites: number[] | undefined;
  market: string[] | undefined;
  fund: string[] | undefined;
  equipmentType: EquipmentType[] | undefined;
  propertyType: number[] | undefined;
  assetManager: number[] | undefined;
}>;

const getPortfolioEquipmentSummary = (
  accountId: number,
  filterParams?: PortfolioEquipmentFilterParams
): Promise<EquipmentSummary> => {
  const queryParams = Object.entries(filterParams ?? {}).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(value && value?.length > 0 && { [snakeCase(key)]: value }),
    }),
    {} as Record<string, Array<number | string | EquipmentType>>
  );

  return request
    .get(`${VITE_SITE_SERVICE_BASE_URL}/equipment/summary/${accountId}`)
    .query(queryParams)
    .then((res) => recursiveCamelCaseCipher(res.body));
};

function useQuery(
  accountId: number,
  filterParams?: PortfolioEquipmentFilterParams,
  keepPreviousData?: boolean
) {
  // sorting filter params to ensure the query key is consistent
  const sortedFilterParams = React.useMemo(() => {
    if (!filterParams) {
      return;
    }

    return Object.entries(filterParams ?? {}).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: value?.sort() }),
      {} as PortfolioEquipmentFilterParams
    );
  }, [filterParams]);
  return useReactQuery({
    queryKey: [PORTFOLIO_EQUIPMENT_QUERY_KEY, accountId, sortedFilterParams],
    queryFn: async () =>
      getPortfolioEquipmentSummary(accountId, sortedFilterParams),
    keepPreviousData: keepPreviousData,
  });
}
