import React, { useMemo } from "react";
import classnames from "classnames";

import { ExtendedSemanticColors } from "../../../colors";
import { PolymorphicComponentPropWithoutRef } from "../../../../utilTypes";

export const variants = [
  "h1_t-42-500",
  "h2_t-30-700",
  "h3_t-24-700",
  "h4_t-20-700",
  "h5_t-18-700",
  "sa_t-16-700",
  "sa_t-14-700",
  "sa_t-12-700",
  "sa_t-10-700",
  "sb_t-24-500",
  "sb_t-20-500",
  "sb_t-18-500",
  "sb_t-16-500",
  "sb_t-14-500",
  "sb_t-12-500",
  "sb_t-10-500",
  "sc_u-18-500",
  "sc_u-16-500",
  "sc_u-14-700",
  "sc_u-12-700",
  "sc_u-10-700",
  "bc_s-16-500",
  "bc_s-14-300",
  "bc_s-14-300-italic",
  "bc_s-12-300",
] as const;

const formats = ["hyperlink", "button_titlecase", "button_caps"] as const;

type Elements = "h1" | "h2" | "h3" | "h4" | "h5" | "sa" | "sb" | "sc" | "bc"; // s elements meaning "subheader" which results in a h6 tag.
type TextCase = "t" | "u" | "s"; // title-case, uppercase, sentence-case
export type TextVariants = (typeof variants)[number];
type Formats = (typeof formats)[number];

type TextComponentOptions = Extract<
  React.ElementType,
  "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "div"
>;

// restrict colors to SemanticColors if onClick is present?
type BaseTextProps<C extends TextComponentOptions> = {
  color?: ExtendedSemanticColors | "inherit";
  customStyles?: any;
  variant?: TextVariants;
  format?: Formats;
  as?: C;
  onClick?: () => void;
  italic?: boolean;
};

type TextProps<C extends TextComponentOptions> =
  PolymorphicComponentPropWithoutRef<C, BaseTextProps<C>>;

const textClasses: Record<TextVariants, string> = {
  "h1_t-42-500": "text-h1_t-42-500",
  "h2_t-30-700": "text-h2_t-30-700",
  "h3_t-24-700": "text-h3_t-24-700",
  "h4_t-20-700": "text-h4_t-20-700",
  "h5_t-18-700": "text-h5_t-18-700",

  "sa_t-16-700": "text-sa_t-16-700",
  "sa_t-14-700": "text-sa_t-14-700",
  "sa_t-12-700": "text-sa_t-12-700",
  "sa_t-10-700": "text-sa_t-10-700",

  "sb_t-24-500": "text-sb_t-24-500",
  "sb_t-20-500": "text-sb_t-20-500",
  "sb_t-18-500": "text-sb_t-18-500",
  "sb_t-16-500": "text-sb_t-16-500",
  "sb_t-14-500": "text-sb_t-14-500",
  "sb_t-12-500": "text-sb_t-12-500",
  "sb_t-10-500": "text-sb_t-10-500",

  "sc_u-18-500": "text-sc_u-18-500",
  "sc_u-16-500": "text-sc_u-16-500",
  "sc_u-14-700": "text-sc_u-14-700",
  "sc_u-12-700": "text-sc_u-12-700",
  "sc_u-10-700": "text-sc_u-10-700",

  "bc_s-16-500": "text-bc_s-16-500",
  "bc_s-14-300": "text-bc_s-14-300",
  "bc_s-14-300-italic": "text-bc_s-14-300 italic",
  "bc_s-12-300": "text-bc_s-12-300",
};

const colorClasses: Record<ExtendedSemanticColors, string> = {
  white: "text-white",
  primary: "text-straps-primary",
  "primary-hover": "text-straps-primary-hover",
  secondary: "text-straps-secondary",
  "secondary-hover": "text-straps-secondary-hover",
  tertiary: "text-straps-tertiary",
  hyperlink: "text-straps-hyperlink",
  "hyperlink-hover": "text-straps-hyperlink-hover",
  body: "text-straps-body",
  brand: "text-straps-brand",
  "brand-hover": "text-straps-brand-hover",
  warning: "text-straps-warning",
  "warning-hover": "text-straps-warning-hover",
  "warning-bg": "text-straps-warning-bg",
  positive: "text-straps-positive",
  "positive-hover": "text-straps-positive-hover",
  "positive-bg": "text-straps-positive-bg",
  negative: "text-straps-negative",
  "negative-hover": "text-straps-negative-hover",
  "negative-bg": "text-straps-negative-bg",
  "accent-1": "text-straps-accent-1",
  "accent-1-hover": "text-straps-accent-1-hover",
  "accent-2": "text-straps-accent-2",
  "accent-3": "text-straps-accent-3",
};

const Text = <C extends TextComponentOptions>({
  className,
  color,
  variant: _variant = "bc_s-16-500",
  format,
  as,
  children,
  onClick,
  italic,
  ...otherProps
}: TextProps<C>) => {
  const [parts] = _variant.split("-") as [`${Elements}_${TextCase}`];
  const [element, textCase] = parts.split("_") as [Elements, TextCase];

  const elementToRender = useMemo(() => {
    if (as) {
      return as;
    }
    return ["h1", "h2", "h3", "h4", "h5"].includes(element) ? element : "div";
  }, [element, as]);

  const variantClasses = useMemo(() => {
    return classnames(textClasses[_variant], {
      underline: format === "hyperlink",
      italic: !!italic,
      capitalize: format === "button_titlecase",
      uppercase: format === "button_caps" || textCase === "u",
    });
  }, [_variant, format, textCase, italic]);

  const classNames = useMemo(() => {
    return classnames(
      className,
      "m-0 transition-colors",
      variantClasses,
      color !== "inherit" && colorClasses[color || "primary"]
    );
  }, [className, color, variantClasses]);

  return React.createElement(
    elementToRender,
    { className: classNames, onClick, ...otherProps },
    children
  );
};

export default Text;
