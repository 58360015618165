import { Flow } from "flow-to-typescript-codemod";

import * as React from "react";
import {
  Form,
  FormProps,
  useForm,
  useFormState,
  FormRenderProps,
} from "react-final-form";

import type { FormApi } from "final-form";
import {
  Dropdown,
  DropdownWrapper,
  Input,
  NavButtonGroup,
} from "../global_components";
import type { InputProps, InputStatuses } from "../global_components/Input";
import type { DropdownProps } from "../global_components/Dropdown";
import { useFieldWithErrors } from "../site__analytics/PlotResources/utils";

import styles from "./BractletForm.module.scss";
import Icon from "../icons";
import SingleDaySelector from "./SingleDaySelector";
import AsyncButton from "@src/straps/derived/AsyncButton/AsyncButton";

export type Status = {
  status: InputStatuses;
  hideIcon?: boolean;
  color?: string;
  message?: string | React.ReactNode;
};
export type StatusGetter = (
  value?: any,
  meta?: any,
  options?: {
    hasExpectedValue?: boolean;
    expectedValue?: any;
    [key: string]: any;
  }
) => Status | undefined;

const BractletFormContext = React.createContext<{
  expectedSubmission: any;
  statusFunction: StatusGetter;
  disableStatuses?: boolean;
  // @ts-expect-error - TS2345 - Argument of type '{}' is not assignable to parameter of type '{ expectedSubmission: any; statusFunction: StatusGetter; disableStatuses?: boolean | undefined; }'.
}>({});

type RenderProp = FormRenderProps<{
  [key: string]: any;
}>;

type FormSubmit =
  | (() => void)
  | ((
      values: {
        [key: string]: any;
      },
      form: FormApi<{
        [key: string]: any;
      }>,
      callback?: (
        errors?: any | null | undefined
      ) => any | null | undefined | null | undefined
    ) => any | Promise<any | null | undefined> | null | undefined);

const BractletForm = (
  props: FormProps<{
    [key: string]: any;
  }> & {
    children?: React.ReactNode;
    render?: (renderProps: RenderProp) => React.ReactElement;
    onSubmit?: FormSubmit;
    onChange?: (arg1?: any) => void;
    expectedSubmission?: any;
    customStatusFunction?: StatusGetter | null;
    disableStatuses?: boolean;
  }
) => {
  const {
    children,
    onSubmit = () => {},
    expectedSubmission,
    customStatusFunction,
    render = (_: FormRenderProps<any>) => {},
    disableStatuses,
    onChange,
    ...formProps
  } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const statusFunction = React.useCallback(
    customStatusFunction || defaultStatusFunction,
    []
  );

  const contextValue = React.useMemo(
    () => ({
      expectedSubmission: expectedSubmission || {},
      statusFunction,
      disableStatuses,
    }),
    [expectedSubmission, statusFunction, disableStatuses]
  );

  return (
    <Form
      {...formProps}
      onSubmit={onSubmit}
      render={(form) => (
        <BractletFormContext.Provider value={contextValue}>
          {children ? children : render(form)}
          {/* @ts-expect-error - TS2322 - Type '{ onChange: (arg1?: any) => void; }' is not assignable to type 'IntrinsicAttributes & object'. */}
          {onChange && <OnChangeListener onChange={onChange} />}
        </BractletFormContext.Provider>
      )}
    />
  );
};

const OnChangeListener = React.memo((props) => {
  // @ts-expect-error - TS2339 - Property 'onChange' does not exist on type '{ children?: ReactNode; }'.
  const { onChange } = props;
  const { values, dirty, valid } = useFormState();
  React.useEffect(() => {
    if (dirty && valid) {
      onChange(values);
    }
  }, [dirty, values, onChange, valid]);
  return null;
});

type FormInputProps = {
  label?: string | false;
  required?: boolean;
  name: string | ((index?: number) => string);
  index?: number;
  onChange?: any;
  disableStatuses?: boolean;
};

interface BractletFormInputProps
  extends FormInputProps,
    Omit<InputProps, "name" | "label" | "onChange"> {
  formatter?: (arg1: string) => string | null | undefined;
  dataCy?: string;
  dataTestId?: string;
}

const BractletFormInput = (props: BractletFormInputProps) => {
  const {
    name: nameProp,
    required,
    onChange: onChangeFunction,
    index,
    disableStatuses,
    dataCy,
    dataTestId,
    formatter = (x: string) => x,
    ...rest
  } = props;

  const { status, value } = useBractletFormField(
    nameProp,
    disableStatuses,
    index
  );

  const name = typeof nameProp === "function" ? nameProp(index) : nameProp;

  const form = useForm();
  return (
    <div
      className={styles.container}
      data-cy={dataCy ?? "input-field"}
      data-testid={dataTestId ?? "input-field"}
    >
      <Input
        {...rest}
        onChange={(e) => {
          const v = formatter(e.target.value);
          form.change(name, v);
          onChangeFunction && onChangeFunction(v);
        }}
        value={value}
        status={status}
      />
    </div>
  );
};

const BractletFormBool = (props: FormInputProps) => {
  const { label, name: nameProp, index, onChange, disableStatuses } = props;

  const { status, value: selected } = useBractletFormField(
    nameProp,
    disableStatuses,
    index
  );

  const name = typeof nameProp === "function" ? nameProp(index) : nameProp;
  const form = useForm();

  return (
    <div className={styles.container}>
      {label && (
        <label>
          {label}
          {props.required && <Icon icon="Require" />}
        </label>
      )}

      {status?.message && (
        <label className={styles.error_label}>{status?.message}</label>
      )}
      <NavButtonGroup
        selected={selected}
        onSelect={({ id }) => {
          form.change(name, id);
          onChange && onChange(id);
        }}
        options={[
          {
            id: false,
            label: "False",
            backgroundColor: "#9a9eb2",
          },
          {
            id: true,
            label: "True",
          },
        ]}
      />
    </div>
  );
};

const BractletFormRange = (props: FormInputProps) => {
  const { label, name: nameProp, index, disableStatuses, onChange } = props;

  const { status, value } = useBractletFormField(
    nameProp,
    disableStatuses,
    index
  );

  const name = typeof nameProp === "function" ? nameProp(index) : nameProp;

  const form = useForm();
  return (
    <div className={styles.container}>
      <label>
        <span>{label}</span>
        <span>
          <b>{value}</b>%
        </span>
      </label>

      {/* @ts-expect-error - TS2367 - This condition will always return 'false' since the types '"Require" | "Unevaluated" | "Valid" | "Invalid" | "BractletSymbol" | "Gresb" | "Duplicate" | undefined' and '"Error"' have no overlap. */}
      {(status?.status === "Warning" || status?.status === "Error") && (
        <label className={styles.error_label}>{status?.message}</label>
      )}
      <Input
        type="range"
        onChange={(e) => {
          form.change(name, Number(e.target.value));
          onChange && onChange(Number(e.target.value));
        }}
        value={value}
        min="0"
        max="100"
        step="1"
      />
    </div>
  );
};

const BractletFormDates = (
  props: FormInputProps & {
    as?: any;
  }
) => {
  const { label, name: nameProp, index, disableStatuses, onChange } = props;

  const { status, value: date } = useBractletFormField(
    nameProp,
    disableStatuses,
    index
  );

  const name = typeof nameProp === "function" ? nameProp(index) : nameProp;
  const form = useForm();

  const [open, setOpen] = React.useState(false);

  const target = React.useRef();

  return (
    // @ts-expect-error - TS2322 - Type 'MutableRefObject<undefined>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'.
    <div className={styles.container} ref={target}>
      {/* {meta.error && <label className={styles.error_label}>{meta.error}</label>} */}
      {props.as ? (
        React.cloneElement(props.as, {
          onClick: () => setOpen((prev) => !prev),
        })
      ) : (
        <Input
          icon="Calendar"
          disabled
          className={styles.date_button}
          label={label}
          value={date}
          onChange={(e) => {}}
          rounded
          gray
          status={status}
          onClick={() => setOpen((prev) => !prev)}
        />
      )}
      {open && (
        <DropdownWrapper
          open={open}
          target={target}
          minHeight={350}
          width={548}
        >
          <SingleDaySelector
            date={date.toString()}
            handleDateChange={(day) => {
              form.change(name, day);
              onChange && onChange(day);
              setOpen(false);
            }}
            onCancel={() => setOpen(false)}
          />
        </DropdownWrapper>
      )}
    </div>
  );
};

const BractletFormSubmit = (
  props: {
    dataCy?: string;
    onClick?: (arg1?: any) => void;
  } & Flow.Diff<
    React.ComponentPropsWithoutRef<typeof AsyncButton>,
    {
      onClick: (e?: any) => any;
    }
  >
) => {
  const { submit } = useForm();

  const { onClick: onClickProp, children, ...rest } = props;

  const onClick = React.useCallback(
    async (e) => {
      onClickProp && onClickProp(e);
      await submit();
    },
    [onClickProp, submit]
  );

  return (
    <AsyncButton {...rest} onClick={onClick}>
      {children}
    </AsyncButton>
  );
};

const BractletFormDropdown = <T extends string | number>(
  props: DropdownProps<T> & FormInputProps
) => {
  const {
    label,
    name: nameProp,
    index,
    onChange,
    required,
    disableStatuses,
    ...rest
  } = props;
  const { status, value } = useBractletFormField(
    nameProp,
    disableStatuses,
    index
  );
  const form = useForm();
  const name = typeof nameProp === "function" ? nameProp(index) : nameProp;

  return (
    <div className={styles.container}>
      {props.label && <label>{props.label}</label>}

      {/* @ts-expect-error - TS2367 - This condition will always return 'false' since the types '"Require" | "Unevaluated" | "Valid" | "Invalid" | "BractletSymbol" | "Gresb" | "Duplicate" | undefined' and '"Error"' have no overlap. */}
      {(status?.status === "Warning" || status?.status === "Error") && (
        <label className={styles.error_label}>{status?.message}</label>
      )}
      <Dropdown
        {...rest}
        initialValue={props.options.find(({ id }) => id === value)}
        onChange={(option) => {
          form.change(name, option?.option?.id);
          onChange && onChange(option);
        }}
      />
    </div>
  );
};

BractletForm.Input = BractletFormInput;
BractletForm.Bool = BractletFormBool;
BractletForm.Range = BractletFormRange;
BractletForm.Dates = BractletFormDates;
BractletForm.Submit = BractletFormSubmit;
BractletForm.Dropdown = BractletFormDropdown;

function usePreviousYear(
  nameProp: string | ((index?: number) => string),
  index: undefined | number,
  value: any
) {
  // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
  const prev = typeof nameProp === "function" ? nameProp(index + 1) : nameProp;

  const previousYears = useFieldWithErrors(prev);

  if (prev === nameProp) {
    return {
      hasPreviousValue: undefined,
      previousValue: undefined,
    };
  }

  const prevAvailable = index !== undefined && previousYears.value;

  return {
    hasPreviousValue: !(prevAvailable && previousYears.value !== value),
    previousValue: index !== undefined ? previousYears.value : undefined,
  };
}

function useBractletFormField(
  nameProp: string | ((index?: number) => string),
  _disableStatuses?: boolean | null,
  index?: number
) {
  const name = typeof nameProp === "function" ? nameProp(index) : nameProp;

  const { value, meta } = useFieldWithErrors(name);

  const { expectedSubmission, disableStatuses } =
    React.useContext(BractletFormContext);

  const expectedValue = resolve(name, expectedSubmission);
  const hasExpectedValue = !expectedValue || value === expectedValue;

  const { hasPreviousValue, previousValue } = usePreviousYear(
    nameProp,
    index,
    value
  );

  const { statusFunction } = React.useContext(BractletFormContext);

  const status =
    disableStatuses || _disableStatuses
      ? undefined
      : statusFunction(value, meta, {
          hasExpectedValue,
          expectedValue,
          hasPreviousValue,
          previousValue,
        });

  return {
    name,
    value,
    meta,
    status,
    expectedValue,
    hasExpectedValue,
    hasPreviousValue,
    previousValue,
  };
}

function resolve(path: string, obj: any) {
  const properties = path
    .split(".")
    .flatMap((p) =>
      p
        .split("[")
        .map((p2, i) => (i === 0 ? p2 : p2.substring(0, p2.length - 1)))
    )
    .filter((s) => s !== "");
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
}

export default BractletForm;

const defaultStatusFunction: StatusGetter = (
  value: any,
  meta: any,
  options?: {
    hasExpectedValue?: boolean;
    hasPreviousValue?: boolean;
    expectedValue?: any;
    previousValue?: any;
  }
) => {
  let status: Status;

  if (value) status = { status: "Valid" };

  if (options?.hasPreviousValue && options?.previousValue && value)
    status = {
      status: "Duplicate",
      color: "green",
    };

  if (options?.hasExpectedValue && options?.expectedValue && value)
    status = {
      status: "BractletSymbol",
      color: "green",
    };

  if (!meta.dirty && meta.initial) status = { status: "Unevaluated" };

  if (!options?.hasExpectedValue && value)
    status = {
      status: "Warning",
      message: `This value is different to Bractlet's expected value of ${
        options?.expectedValue || "missing expected value"
      }`,
    };

  if (options?.previousValue && !value)
    status = {
      status: "Duplicate",
      message: `For this field, the previous year's value was ${
        options?.previousValue || "missing expected value"
      }`,
    };

  if (!options?.hasExpectedValue && !value)
    status = {
      status: "BractletSymbol",
      message: `For this field, Bractlet has calculated a value of ${
        options?.expectedValue || "missing expected value"
      }`,
    };

  if (meta.error) status = { status: "Invalid", message: meta.error };

  // @ts-expect-error - TS2454 - Variable 'status' is used before being assigned.
  return status;
};
