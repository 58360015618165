import {
  useMutation,
  useQuery as useReactQuery,
  useQueryClient,
} from "react-query";
import { VITE_UTILITIES_BASE_URL } from "../../../env";
import { CamelCasedPropertiesDeep } from "type-fest";
import { recursiveCamelCaseCipher } from "../../postgrestApi";
import request from "../../request";
import { ClientOutput } from "../client";

const emissions = {
  useQuery,
  usePut,
};

export default emissions;

const QUERY_KEY = "emissions-summary";

export type SiteEmissionsSummary = CamelCasedPropertiesDeep<
  ClientOutput["utilities"]["SiteEmissions"]
>;
export type UtilityMonthlyCompletion = CamelCasedPropertiesDeep<
  ClientOutput["utilities"]["UtilityMonthlyCompletion"]
>;
export type EmissionsSummarySnakeCase =
  ClientOutput["utilities"]["EmissionsSummary"];
export type EmissionsSummary =
  CamelCasedPropertiesDeep<EmissionsSummarySnakeCase>;
export type DatePeriod = ClientOutput["utilities"]["DatePeriod"];
export type UtilityStatus = ClientOutput["utilities"]["UtilityStatus"];
export type DateRange = CamelCasedPropertiesDeep<
  ClientOutput["utilities"]["DateRange"]
>;

type QueryParams = {
  siteIds?: number[];
  datePeriod: DatePeriod;
};

const getEmissionsSummary = (
  accountId: number | undefined,
  params: QueryParams
): Promise<EmissionsSummary> =>
  request
    .get(`${VITE_UTILITIES_BASE_URL}/sites/emissions_summary`)
    .query({
      account_id: accountId,
      date_period: params.datePeriod,
      ...(params.siteIds && { site_ids: params.siteIds }),
    })
    .then((res) => recursiveCamelCaseCipher(res.body));

function useQuery(accountId: number | undefined, params: QueryParams) {
  return useReactQuery({
    enabled: accountId !== undefined,
    queryKey: [QUERY_KEY, accountId, params.datePeriod, params?.siteIds],
    queryFn: async () => getEmissionsSummary(accountId, params),
    select: (data) => ({
      ...data,
      sites: data.sites.map((site) => ({
        ...site,
        emissions: {
          ...site.emissions,
          emissionsIntensity: site.emissions.emissionsIntensity * 1000, // convert from MTCO2e -> kgCO2e
        },
      })),
    }),
  });
}

const useInvalidateEmissionsSummaryQuery = (accountId: number) => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries([QUERY_KEY, accountId]);
};

const putScope3 = (
  siteId: number,
  requestBody: { year: number; emissions: number }
) =>
  request
    .put(`${VITE_UTILITIES_BASE_URL}/sites/${siteId}/emissions/scope_3`)
    .send(requestBody);

function usePut(siteId: number | null, accountId: number) {
  const invalidateEmissionsSummaryQuery =
    useInvalidateEmissionsSummaryQuery(accountId);

  return useMutation({
    mutationFn: (requestBody: { year: number; emissions: number }) => {
      if (!siteId) throw new Error("Site ID is not defined");
      return putScope3(siteId, requestBody);
    },
    onSuccess: async () => await invalidateEmissionsSummaryQuery(),
  });
}
