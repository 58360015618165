import { camelCase } from "lodash";
import { useQuery } from "react-query";
import { Utility } from "../../../types";
import { recursiveCamelCaseCipher } from "../../postgrestApi";
import { UTILITY_BILL_CONSUMPTION_KEY } from "./consumption";
import { ClientOutput } from "../client";
import { CamelCasedProperties } from "type-fest";
import { UnitParam } from "./bill";
import request from "@src/global_functions/request";
import { VITE_UTILITIES_BASE_URL } from "@src/env";

// @todo can use generated types with no modifications when https://bractlet.atlassian.net/browse/INNO-765 is done
export type BillInvoiceSnakeCase = Omit<
  ClientOutput["utilities"]["BillInvoice"],
  "taxes_and_other" | "total_cost" | "total_consumption"
> & {
  taxes_and_other?: number | null;
  total_cost?: number | null;
  total_consumption: number;
};

export type BillInvoice = Omit<
  CamelCasedProperties<BillInvoiceSnakeCase>,
  "utilityType"
> & { utilityType: Utility };

const billInvoice = {
  useQueryAll,
} as const;

export default billInvoice;

const getBillInvoices = (site_id: number, unit: UnitParam) =>
  // use superagent here as fake timer/promise flushing in test does not seem to resolve promise
  // when msw rest.get handler intercepts fetch request
  request
    .get(`${VITE_UTILITIES_BASE_URL}/sites/${site_id}/bill_invoices`)
    .query({ unit })
    .then(
      ({ body }: { body: BillInvoiceSnakeCase[] }) =>
        body.map(({ utility_type, ...invoice }) => ({
          ...recursiveCamelCaseCipher(invoice),
          utilityType: camelCase(utility_type),
        })) as BillInvoice[]
    )
    .catch((e) => {
      throw new Error(e);
    });

function useQueryAll(siteId: number, unit: UnitParam) {
  return useQuery(
    [UTILITY_BILL_CONSUMPTION_KEY, siteId, "bill-invoice", unit],
    () => getBillInvoices(siteId, unit)
  );
}
