import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { VITE_FIREBASE_API_KEY, VITE_FIREBASE_AUTH_DOMAIN } from "./env";

const firebaseConfig = {
  apiKey: VITE_FIREBASE_API_KEY,
  authDomain: VITE_FIREBASE_AUTH_DOMAIN,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
