import classNames from "classnames";
import * as React from "react";
import { createPortal } from "react-dom";
import { Transition } from "react-transition-group";

import { useForm, useFormState } from "react-final-form";
import { TransitionStatus } from "react-transition-group/Transition";
import AsyncButton from "../../../derived/AsyncButton/AsyncButton";
import Button from "../../buttons/Button/Button";
import { ClickableDiv } from "../../../utils/ClickableDiv";
import Icon from "../../icons/Icon/Icon";
import Text from "../../type/Text/Text";
import { PORTAL_ROOT_ID } from "@src/PortalRoot";

const ESCAPE_KEYCODE = 27;

type BaseModalProps = {
  open?: boolean;
  setOpen?: (isOpen: boolean) => void;
  onClose?: () => void | Promise<void>;
  onOpen?: () => void | Promise<void>;
  children: React.ReactNode;
  "data-testid"?: string;
};

interface UnwrappedModalProps extends BaseModalProps {
  state: TransitionStatus;
  setOpen: (isOpen: boolean) => void;
}

const UnwrappedModal = (props: UnwrappedModalProps) => {
  const {
    open,
    state,
    children,
    setOpen,
    "data-testid": dataTestid,
    onClose = () => {},
  } = props;

  const [hasOpened, setHasOpened] = React.useState(false);

  React.useEffect(() => {
    const listener = (e: any) => {
      if (e.keyCode === ESCAPE_KEYCODE) {
        setOpen(false);
        onClose();
      }
    };

    window.addEventListener("keyup", listener);

    return () => window.removeEventListener("keyup", listener);
  }, [onClose, setOpen]);

  React.useEffect(() => {
    if (!hasOpened && state !== "exited") {
      setHasOpened(true);
    } else if (onClose && hasOpened && !open && state === "exited") {
      onClose();
      setHasOpened(false);
    }
  }, [onClose, hasOpened, open, state]);

  const root = document.getElementById(PORTAL_ROOT_ID);

  if (!root || state === "exited") return null;

  return createPortal(
    <div
      className={classNames(
        "fixed bottom-0 left-0 right-0 top-0 z-[1000] flex items-center justify-center overflow-auto px-[10vh] backdrop-blur-[1px] transition-opacity",
        {
          "pointer-events-auto opacity-100": state === "entered",
          "pointer-events-none opacity-0":
            state === "exiting" || state === "entering",
        }
      )}
      data-testid={dataTestid}
    >
      <ClickableDiv
        className="fixed bottom-0 left-0 right-0 top-0 bg-straps-primary opacity-30"
        onClick={(e) => {
          e.stopPropagation();
          if (state === "entered") {
            setOpen(false);
            onClose();
          }
        }}
      />
      <ClickableDiv
        className="relative z-[1001] mx-auto max-h-[80vh] max-w-[80vw] bg-white"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </ClickableDiv>
    </div>,
    root
  );
};

interface ModalProps extends BaseModalProps {
  // Element must have `onClick` prop
  as?: React.ReactElement;
}

const Modal = ({ as, ...baseModalProps }: ModalProps) => {
  const [_open, _setOpen] = React.useState(false);
  const open =
    baseModalProps.open === undefined ? _open : Boolean(baseModalProps.open);
  const setOpen =
    baseModalProps.setOpen === undefined ? _setOpen : baseModalProps.setOpen;
  const contextValue = React.useMemo(
    () => ({
      setOpen,
    }),
    [setOpen]
  );

  return (
    <>
      {as &&
        React.cloneElement(as, {
          onClick: (e: Event) => {
            setOpen(true);
            baseModalProps.onOpen && baseModalProps.onOpen();
            e.stopPropagation();
          },
        })}
      {/** In general, the fade duration is set by the css transition.
       * The values for timeout below should: allow enough time for at
       * least one frame in the "enter" state which can then be animated
       * from. And then enough time before setting "exited" for the
       * fade out to complete */}
      <Transition in={open} appear timeout={{ enter: 1, exit: 250 }}>
        {(state) => (
          <ModalContext.Provider value={contextValue}>
            <UnwrappedModal
              {...baseModalProps}
              state={state}
              open={open}
              setOpen={setOpen}
            />
          </ModalContext.Provider>
        )}
      </Transition>
    </>
  );
};

interface ModalContextState {
  setOpen: (isOpen: boolean) => void;
}
export const ModalContext = React.createContext<ModalContextState>({
  setOpen: () => {},
});

const ModalCloseButton = ({
  onClick,
  as,
  ...props
}: React.ComponentPropsWithRef<typeof Button> & {
  as?: React.ReactElement;
}) => {
  const { setOpen } = React.useContext(ModalContext);
  if (as) {
    return React.cloneElement(as, {
      onClick: () => {
        onClick?.();
        setOpen(false);
      },
    });
  }
  return (
    <Button
      onClick={() => {
        onClick?.();
        setOpen(false);
      }}
      {...props}
    >
      {props.children === undefined ? "Cancel" : props.children}
    </Button>
  );
};

Modal.Close = ModalCloseButton;
Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;
Modal.FormFooter = ModalFormFooter;

function ModalFormFooter(props: Omit<ModalFooterProps, "onSubmit">) {
  const form = useForm();
  const state = useFormState();

  const onSubmit = React.useCallback(async () => {
    await form.submit();
  }, [form]);

  return (
    <ModalFooter
      submitDisabled={!state.valid && state.submitFailed}
      onSubmit={onSubmit}
      {...props}
      closeAfterSubmit={state.valid ? props.closeAfterSubmit : false}
    />
  );
}

interface ModalHeaderProps {
  iconProps?: React.ComponentPropsWithoutRef<typeof Icon>;
  title?: string;
  children?: React.ReactNode;
  showCloseButton?: boolean;
  onClose?: () => void;
}
function ModalHeader({
  iconProps,
  title,
  children,
  showCloseButton,
  onClose,
}: ModalHeaderProps) {
  const { setOpen } = React.useContext(ModalContext);

  return (
    <div className="flex h-15 items-center justify-between bg-straps-primary px-7">
      <div className="flex items-center gap-3">
        {iconProps && <Icon color="white" {...iconProps} />}
        {title && (
          <Text variant="h4_t-20-700" color="white">
            {title}
          </Text>
        )}
        {children}
      </div>
      {showCloseButton && (
        <ClickableDiv
          onClick={onClose ? onClose : () => setOpen(false)}
          className="flex cursor-pointer items-center"
        >
          <Icon name="close" color="white" />
        </ClickableDiv>
      )}
    </div>
  );
}

interface ModalFooterProps {
  children?: React.ReactNode;
  showCancel?: boolean;
  onCancel?: () => void;
  submitText?: string;
  submitDisabled?: boolean;
  onSubmit: (e: React.MouseEvent<HTMLElement>) => Promise<unknown> | void;
  closeAfterSubmit?: boolean;
}
function ModalFooter({
  children,
  onCancel,
  onSubmit,
  submitDisabled,
  submitText = "Done",
  closeAfterSubmit,
  showCancel = true,
}: ModalFooterProps) {
  const { setOpen } = React.useContext(ModalContext);
  return (
    <div className="flex h-15 items-center border-t border-t-straps-accent-3 bg-white px-7">
      {children}
      <div className="ml-auto flex items-center">
        {showCancel && (
          <Text
            variant="sb_t-12-500"
            onClick={() => {
              if (onCancel) {
                onCancel();
              } else {
                setOpen(false);
              }
            }}
            color="secondary"
            className="relative mx-4 cursor-pointer"
          >
            Cancel
          </Text>
        )}
        <AsyncButton
          onClick={async (e) => {
            await onSubmit(e);
            if (closeAfterSubmit) {
              setOpen(false);
            }
          }}
          color="positive"
          disabled={submitDisabled}
          className="w-24 min-w-min whitespace-nowrap"
          data-cy="modal-save-button"
          dataTestId="modal-save-button"
        >
          {submitText}
        </AsyncButton>
      </div>
    </div>
  );
}

export default Modal;
