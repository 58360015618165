import FetchClients, { ClientOutput } from "../client";
import {
  useMutation,
  useQuery as useReactQuery,
  useQueryClient,
} from "react-query";
import { CamelCasedProperties } from "type-fest";
import { recursiveCamelCaseCipher } from "@src/global_functions/postgrestApi";
import { Disposition } from "@src/backpack-console/pages/SiteDashboard/Documents/types";
import React from "react";

const CERTIFICATION_DOCUMENTS_QUERY_KEY = "certification-documents";
const keys = {
  all: [CERTIFICATION_DOCUMENTS_QUERY_KEY],
  site: (siteId: number) => [CERTIFICATION_DOCUMENTS_QUERY_KEY, "site", siteId],
  certification: (siteId: number, certificationId?: number) => [
    CERTIFICATION_DOCUMENTS_QUERY_KEY,
    "site",
    siteId,
    "certification",
    certificationId,
  ],
};

function useInvalidateQueries() {
  const queryClient = useQueryClient();
  const invalidate = React.useCallback(
    ({
      siteId,
      certificationId,
    }: {
      siteId?: number;
      certificationId?: number;
    }) =>
      Promise.all([
        ...(typeof siteId === "number"
          ? [queryClient.invalidateQueries(keys.site(siteId))]
          : []),
        ...(typeof certificationId === "number"
          ? [queryClient.invalidateQueries(keys.certification(certificationId))]
          : []),
      ]),
    [queryClient]
  );
  return invalidate;
}

const certificationDocuments = {
  useQueryDownloadLinks,
  useQuerySiteDocumentInfo,
  mutations: {
    usePost,
    useDelete,
  },
};

type CertificationDocRequest = {
  certificationId?: number;
  disposition?: Disposition;
  includePreview?: boolean;
};

export type CertificationDocRow = CamelCasedProperties<
  ClientOutput["d3"]["CertificationDocRow"]
>;
export type CertificationDoc = CamelCasedProperties<
  ClientOutput["d3"]["CertificationDoc"]
>;
const getCertificationDocumentDownloadLinks = ({
  certificationId,
  disposition = "Attachment",
  includePreview = true,
}: CertificationDocRequest): Promise<CertificationDoc[]> => {
  if (typeof certificationId !== "number") {
    throw new Error("Must include certificationId");
  }
  return FetchClients.d3
    .GET("/certifications/download", {
      params: {
        query: {
          certification_id: certificationId,
          disposition: disposition,
          include_preview: includePreview,
        },
      },
    })
    .then((res) => res.data && recursiveCamelCaseCipher(res.data));
};

function useQueryDownloadLinks(
  siteId: number,
  params: CertificationDocRequest
) {
  return useReactQuery({
    queryKey: keys.certification(siteId, params.certificationId),
    queryFn: () => getCertificationDocumentDownloadLinks(params),
    enabled: !!params.certificationId,
  });
}

const getSiteCertificationDocumentInfo = (
  siteId: number
): Promise<CertificationDocRow[]> => {
  return FetchClients.d3
    .GET("/certifications/list_documents", {
      params: {
        query: {
          site_id: siteId,
        },
      },
    })
    .then((res) => res.data && recursiveCamelCaseCipher(res.data));
};

function useQuerySiteDocumentInfo(siteId: number) {
  return useReactQuery({
    queryKey: keys.site(siteId),
    queryFn: () => getSiteCertificationDocumentInfo(siteId),
  });
}

async function createCertiticationDoc(certificationId: number, file: File) {
  const response = await FetchClients.d3.POST("/certifications/upload", {
    params: {
      query: {
        certification_id: certificationId,
        filename: file.name,
      },
    },
    parseAs: "text",
    // @ts-expect-error - TS2740 - Type 'File' is missing the following properties from type 'number[]': length, pop, push, concat, and 28 more.
    // 'File' type technically does not match what the openapi spec expects, but the network request still works
    body: file,
    bodySerializer: (body) => body,
  });
  if (response.error) {
    throw new Error("Failed to create certification");
  }
}

function usePost(siteId: number) {
  const invalidate = useInvalidateQueries();
  return useMutation({
    mutationFn: ({
      certificationId,
      file,
    }: {
      certificationId: number;
      file: File;
    }) => createCertiticationDoc(certificationId, file),
    onSettled: () => invalidate({ siteId }),
  });
}

function deleteCertiticationDoc(fileIds: number[]) {
  return FetchClients.d3.DELETE("/certifications/delete", {
    params: { query: { file_ids: fileIds } },
  });
}

function useDelete(siteId: number) {
  const invalidate = useInvalidateQueries();
  return useMutation({
    mutationFn: ({ fileIds }: { certificationId: number; fileIds: number[] }) =>
      deleteCertiticationDoc(fileIds),
    onSettled: (_date, _error, variables) =>
      invalidate({ siteId, certificationId: variables.certificationId }),
  });
}

export default certificationDocuments;
