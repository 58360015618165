import { NotFoundError } from "../backpackSdk/tenantsAndSpaces/util";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ResponseError } from "superagent";
import { isDev } from "@src/env";

const isSuperagentResponseErrorCode = (e: any): e is ResponseError => {
  return Boolean(e?.response?.statusCode);
};

const DEFAULT_MAX_RETRIES = 3; // react-query default
export const retryIfNot404 = (retryCount: number, err: unknown) => {
  if (err instanceof NotFoundError || isSuperagentResponseErrorCode(err)) {
    if (retryCount >= DEFAULT_MAX_RETRIES) {
      return false;
    }
    return err.response?.statusCode !== 404;
  }
  if (isDev) {
    // eslint-disable-next-line
    console.warn(
      "[retryIfNot404] Error is neither NotFoundError nor Superagent ResponseError - cannot determine status code. Defaulting to 3 query retries."
    );
  }
  return retryCount < DEFAULT_MAX_RETRIES;
};
