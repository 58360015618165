import React from "react";
import ReactDOM from "react-dom";
import { ReactQueryDevtools } from "react-query/devtools";

import "./stylesheets/tailwind.css";
import "./stylesheets/index.scss";

import "./timezones";
import "./register-chart-plugins";

import AppRouter from "./AppRouter";

import BugsnagErrorBoundary from "./bugsnag";

import LiveSession from "./livesession";
import "./pendo";
import { isDev } from "./env";

import { QueryClientProvider } from "react-query";
import { queryClient } from "./utils/react-query";

const root = document.getElementById("root");

LiveSession.initialize();

if (root) {
  ReactDOM.render(
    <BugsnagErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AppRouter />
        {isDev && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </BugsnagErrorBoundary>,
    root
  );
}
