import env from "env-var";

// MYSTERY ??
export const VITE_TIMESERIES_JWT = env.get("VITE_TIMESERIES_JWT").asString();

export const isProd = import.meta.env.MODE === "production";
export const isDev = import.meta.env.MODE === "development";
export const isTest = import.meta.env.MODE === "test";

export const VITE_LEDGER_BASE_URL = env
  .get("VITE_LEDGER_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_UPLOAD_BASE_URL = env
  .get("VITE_UPLOAD_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_REPORTER_BASE_URL = env
  .get("VITE_REPORTER_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_SITE_SERVICE_BASE_URL = env
  .get("VITE_SITE_SERVICE_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_TENANTS_AND_SPACES_BASE_URL = env
  .get("VITE_TENANTS_AND_SPACES_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_UTILITIES_BASE_URL = env
  .get("VITE_UTILITIES_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_PORTABLE_DOCUMENT_FORMAT_BASE_URL = env
  .get("VITE_PORTABLE_DOCUMENT_FORMAT_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_TIMESCALE_QUERY_API_BASE_URL = env
  .get("VITE_TIMESCALE_QUERY_API_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_REWARDS_SANDBOX_BASE_URL = env
  .get("VITE_REWARDS_SANDBOX_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_BACKPACK_URL = env
  .get("VITE_BACKPACK_URL")
  .required(!isTest)
  .asString();

export const VITE_ARCADIA_BASE_URL = env
  .get("VITE_ARCADIA_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_ACTIVITY_LOGS_BASE_URL = env
  .get("VITE_ACTIVITY_LOGS_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_MARKETPLACE_BASE_URL = env
  .get("VITE_MARKETPLACE_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_AUTH_SERVICE_BASE_URL = env
  .get("VITE_AUTH_SERVICE_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_EMAIL_SERVICE_BASE_URL = env
  .get("VITE_EMAIL_SERVICE_BASE_URL")
  .required(isProd)
  .asString();

export const VITE_LIVESESSION_ID = env
  .get("VITE_LIVESESSION_ID")
  .required(isProd)
  .asString();

export const VITE_BRACTLET_ENERGY_STAR_ID = env
  .get("VITE_BRACTLET_ENERGY_STAR_ID")
  .required(!isTest)
  .asInt();

export const VITE_FIREBASE_API_KEY = env
  .get("VITE_FIREBASE_API_KEY")
  .required(!isTest)
  .asString();

export const VITE_FIREBASE_AUTH_DOMAIN = env
  .get("VITE_FIREBASE_AUTH_DOMAIN")
  .required(!isTest)
  .asString();

// DEVELOPMENT

export const VITE_ENABLE_UNSAFE_REQUESTS = env
  .get("VITE_ENABLE_UNSAFE_REQUESTS")
  .asBoolStrict();

export const VITE_LD_CLIENT_ID = env
  .get("VITE_LD_CLIENT_ID")
  .required(!isTest)
  .asString();

export const PENDO_KEY = env
  .get("VITE_BACKPACK_PENDO_KEY")
  .required(isProd)
  .asString();

export const VITE_MOCK_SERVICE_WORKER = env
  .get("VITE_MOCK_SERVICE_WORKER")
  .asBoolStrict();

export const VITE_APP_VERSION = env.get("VITE_APP_VERSION").asString();

export const VITE_DISABLE_SUPER_ADMIN_IN_CLIENT = env
  .get("VITE_DISABLE_SUPER_ADMIN_IN_CLIENT")
  .asBoolStrict();
