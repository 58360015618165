import classNames from "classnames";
import {
  ComponentPropsWithoutRef,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { CSSTransition } from "react-transition-group";

import { Text } from "@src/straps/base";
import Icon from "@src/straps/base/icons/Icon/Icon";

type NotifierProps = {
  open: boolean;
  onClose: () => void;
  titleText: string;
  titleIcon: ComponentPropsWithoutRef<typeof Icon>["name"];
  disableMinimize?: boolean;
  containerTestId?: string;
  contentTestId?: string;
  width?: number;
};

// Opens a notification pop up from the bottom of the screen used to keep track of long-running tasks.
export const Notifier = ({
  open,
  onClose,
  titleText,
  titleIcon,
  disableMinimize,
  containerTestId,
  contentTestId,
  width = 240,
  children,
}: PropsWithChildren<NotifierProps>) => {
  const [minimized, setMinimized] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const _onClose = useCallback(() => {
    onClose();
    setTimeout(() => setMinimized(false), 500);
  }, [onClose]);

  useEffect(() => {
    if (disableMinimize && minimized) {
      setMinimized(false);
    }
  }, [disableMinimize, minimized]);

  const onMinimize = useCallback(() => setMinimized(!minimized), [minimized]);

  return (
    <CSSTransition
      in={open}
      nodeRef={ref}
      timeout={{
        enter: 0,
        exit: 500,
      }}
      classNames={{
        enter: "translate-y-full",
        enterDone: "translate-y-0 transition-transform duration-500",
        exitActive: "translate-y-full transition-transform duration-500",
      }}
      mountOnEnter
      unmountOnExit
    >
      <div
        ref={ref}
        data-testid={containerTestId}
        className="fixed bottom-0 right-[100px] z-[100000] shadow-lg"
        style={{ width }}
      >
        <div className="flex h-15 items-center justify-between bg-straps-primary-hover px-5">
          <div className="flex items-center justify-between">
            <Icon
              name={titleIcon}
              size="extraLarge"
              className="text-pure-white"
            />
            <Text className="pl-3.5 font-bold" color="white">
              {titleText}
            </Text>
          </div>
          {!disableMinimize ? (
            <Icon
              aria-label="Minimize"
              name="caret-down"
              className={classNames(
                "text-straps-tertiary transition-transform hover:cursor-pointer",
                {
                  "rotate-180": minimized,
                }
              )}
              onClick={onMinimize}
            />
          ) : (
            <Icon
              aria-label="Close"
              name="close"
              className="text-straps-tertiary hover:cursor-pointer"
              onClick={_onClose}
            />
          )}
        </div>
        <div
          data-testid={contentTestId}
          className={classNames("flex flex-col bg-pure-white", {
            "transition-all": open,
            "h-0": minimized,
            "h-64": !minimized,
            "opacity-0": minimized,
            "opacity-100": !minimized,
          })}
        >
          {children}
        </div>
      </div>
    </CSSTransition>
  );
};
