import request from "../../../global_functions/request";
import type { ResponseError } from "superagent";
import { BackpackResponseErrorBody } from "../errors";

import { useMutation, useQuery as useReactQuery } from "react-query";
import type { User } from "@src/types";
import {
  formatDataObj,
  snakeCaseCipher,
  recursiveCamelCaseCipher,
  recursiveSnakeCaseCipher,
} from "@src/global_functions/postgrestApi";
import FetchClients, { ClientOutput } from "../client";
import { CamelCasedProperties } from "type-fest";

const users = {
  useQuery,
  mutations: {
    useUpdateUser,
  },
} as const;

export default users;

const getUser = (userId: number): Promise<User | null | undefined> =>
  request
    .get("/rest/users")
    .query({ id: `eq.${userId}` })
    .then(({ body }) => recursiveCamelCaseCipher(body[0]));

function useQuery(userId: number) {
  return useReactQuery({
    queryFn: () => getUser(userId),
    queryKey: ["user", userId],
  });
}

type AllowListBody = CamelCasedProperties<
  ClientOutput["site"]["UserSiteAccessUpdate"]
>;

// post the siteIds a user can access
export const postToAllowList = async (body: AllowListBody) => {
  const { response, error } = await FetchClients.site.POST("/allowlist", {
    body: recursiveSnakeCaseCipher(body),
  });
  if (response.ok) return;
  throw new Error(error);
};

const updateUser = (userId: number, updatedProps: Partial<User>) =>
  request
    .patch("/rest/users")
    .query({ id: `eq.${userId}` })
    .send(formatDataObj(updatedProps, snakeCaseCipher, { allowNulls: true }));

interface UpdateUserError extends ResponseError {
  response: {
    body?: BackpackResponseErrorBody & { details?: string };
  } & ResponseError["response"];
}
const isUpdateUserError = (e: any): e is UpdateUserError =>
  Boolean(e?.response?.body?.details);
const updateUserAndBuildings = async (
  userId: number,
  updatedProps: Partial<User>,
  allowedSiteIds: AllowListBody["allowedSiteIds"]
) => {
  try {
    await updateUser(userId, updatedProps);
    await postToAllowList({ userId, allowedSiteIds });
  } catch (e) {
    if (isUpdateUserError(e)) {
      const message: string = e.response.body.details;
      // throw an error only if postgrest error is that email already exists
      if (/^(?=.*\bemail\b)(?=.*\balready\b)(?=.*\bexists\b).*/.test(message)) {
        throw new Error("Email has already been taken");
      }
    }
    throw new Error("Failed to update user.");
  }
};

function useUpdateUser() {
  return useMutation({
    mutationFn: ({
      userId,
      updatedProps,
      allowedSiteIds,
    }: {
      userId: number;
      updatedProps: Partial<User>;
      allowedSiteIds: AllowListBody["allowedSiteIds"];
    }) => updateUserAndBuildings(userId, updatedProps, allowedSiteIds),
  });
}
