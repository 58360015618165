import { snakeCase } from "lodash";
import moment from "moment";
import * as R from "ramda";
import request from "../request";

import type {
  DataStreamSummary,
  DataStreamTypes,
  ID,
  IntegrationStatusType,
  UtilityBillManualUpload,
} from "../../types";

import {
  bugsnagGeneralErrorHandler,
  bugsnagPostgrestErrorHandler,
  recursiveCamelCaseCipher,
} from "./common";

import { useQuery } from "react-query";
import { VITE_UPLOAD_BASE_URL } from "../../env";

const inboundUtilityBillCipher = {
  id: "id",
  site_id: "siteId",
  unit_id: "unitId",
  unit: "unit",
  account_id: "accountId",
  demand_unit: "demandUnit",
  filename: "filename",
  file_type: "fileType",
  uploaded_at: "uploadedAt",
  utility: "utility",
  meter_id: "meterId",
  service_period_start: "startDate",
  service_period_end: "endDate",
  peak_time: "peakOccurred",
  power_factor: "powerFactor",
  fixed_cost: "fixedCost",
  taxes: "taxes",
  total: "totalSpend",
  valid: "isValid",
  parsed_date_id: "parsedDateId",
  month: "month",
  start_date: "parsedStartDate",
  end_date: "parsedEndDate",
  manual_parsed_date: "manualParsedMonth",
  total_consumption: "totalConsumption",
  total_demand: "totalDemand",
  num_of_consumption_tiers: "numOfConsumptionTiers",
  num_of_demand_tiers: "numOfDemandTiers",
  energy_star_id: "energyStarId",
} as const;

const outboundUtilityBillEncoder = {
  ...R.invertObj(inboundUtilityBillCipher),
  // @ts-expect-error - TS7006 - Parameter 'key' implicitly has an 'any' type. | TS7006 - Parameter 'value' implicitly has an 'any' type.
  utility: (key, value) => ({ utility: snakeCase(value) }),
} as const;
// @ts-expect-error - TS2339 - Property 'month' does not exist on type '{ readonly utility: (key: any, value: any) => { utility: string; }; }'.
delete outboundUtilityBillEncoder.month;
// @ts-expect-error - TS2339 - Property 'numOfConsumptionTiers' does not exist on type '{ readonly utility: (key: any, value: any) => { utility: string; }; }'.
delete outboundUtilityBillEncoder.numOfConsumptionTiers;
// @ts-expect-error - TS2339 - Property 'numOfDemandTiers' does not exist on type '{ readonly utility: (key: any, value: any) => { utility: string; }; }'.
delete outboundUtilityBillEncoder.numOfDemandTiers;

function getUtilityBillManualUploads(
  siteId: number
): Promise<UtilityBillManualUpload[]> {
  return request
    .get(`/rest/v_utility_bill_manual_uploads`)
    .query({ site_id: `eq.${siteId}` })
    .then(({ body }) => recursiveCamelCaseCipher(body));
}

// Deprecated - migrating to new manual bills endpoints in backpack sdk with arcadia utilities data
export function useUtilityBillManualUploadsQuery({
  siteId,
  enabled = true,
  combineWasteWater = true,
}: {
  siteId: number;
  enabled?: boolean;
  // Changes waste water items to water
  combineWasteWater?: boolean;
}) {
  return useQuery({
    queryKey: ["utilityBillManualUploads", siteId],
    queryFn: () => getUtilityBillManualUploads(siteId),
    enabled,
    select: (items) =>
      combineWasteWater
        ? items.map((item) => ({
            ...item,
            utilityType:
              item.utilityType === "wasteWater" ? "water" : item.utilityType,
          }))
        : items,
  });
}

export const downloadManualUtilityBill = (fileId: string | number) =>
  request
    .get(`${VITE_UPLOAD_BASE_URL}/bill/download`)
    .query({ file_id: fileId })
    .then((res) => res.text)
    .catch((error) => bugsnagGeneralErrorHandler(error));

// Returns a url to a preview image
export const getPreviewManualUtilityBill = (
  fileId: string | number
): Promise<string | undefined> =>
  request
    .get(`${VITE_UPLOAD_BASE_URL}/bill/preview`)
    .query({ file_id: fileId })
    .then(({ body }) => body?.[fileId])
    .catch((error) => bugsnagGeneralErrorHandler(error));

const getUtilityProviders = (): Promise<
  Array<{
    id: ID;
    name: string;
  }>
> =>
  request
    .get(`/rest/utility_providers`)
    .query({ order: "name" })
    .then(({ body }) => body)
    .catch(bugsnagPostgrestErrorHandler);

export const useUtilityProvidersQuery = () =>
  useQuery({
    queryKey: "utilityProviders",
    queryFn: getUtilityProviders,
  });

const updateMeterActivationStatus = (meterId: number, active: boolean) =>
  request
    .patch(`/rest/utility_meters`)
    .query({ id: `eq.${meterId}` })
    .send({ deactivated_at: active ? null : moment().format() })
    .catch(bugsnagPostgrestErrorHandler);

const getSiteIntegrationStatus = async (
  siteId: string | number
): Promise<IntegrationStatusType[]> => {
  return request
    .get(`/rest/integration_status`)
    .query({ site_id: `eq.${siteId}` })
    .then(({ body }) => body)
    .catch(bugsnagPostgrestErrorHandler);
};

const querySiteDataStreams = async ({
  siteId,
  filter: { query, types, statuses },
  offset,
  limit,
}: {
  siteId: string | number;
  filter: {
    query?: string;
    types: Array<any>;
    statuses: Array<any>;
  };
  offset: number;
  limit: number;
}): Promise<DataStreamSummary[]> => {
  let params: any = {
    site_id: `eq.${siteId}`,
    stream_type: `in.(${types.map((e) => `"${e}"`).join(",")})`,
    offset,
    limit,
  };

  if (!query) {
    params["order"] = "last_heard_from_at.desc.nullslast";
  } else {
    params["bractlet_name"] = `fts.${query}`; // full-text search
  }

  if (statuses.length === 1) {
    if (statuses.includes("online")) {
      params["is_online"] = "eq.true";
    }
    if (statuses.includes("offline")) {
      params["is_online"] = "eq.false";
    }
  }

  return request
    .get(`/rest/bep_data_streams_summary`)
    .query(params)
    .then(({ body }) => body)
    .catch(bugsnagPostgrestErrorHandler);
};

const hasBractletId = (dataStream: any): boolean =>
  dataStream.bractlet_id !== null;

const searchSiteDataStreams = (
  siteId: number,
  types: DataStreamTypes[]
): Promise<Array<DataStreamSummary[]>> => {
  return Promise.all(
    types.map((type) => {
      return request
        .get(`/rest/bep_data_streams_summary`)
        .query({ site_id: `eq.${siteId}`, stream_type: `eq.${type}` })
        .then(({ body }) => R.filter(hasBractletId, body))
        .catch(function (error) {
          bugsnagPostgrestErrorHandler(error);
          return [];
        });
    })
  );
};

export {
  updateMeterActivationStatus,
  getSiteIntegrationStatus,
  querySiteDataStreams,
  searchSiteDataStreams,
};
