import { auth } from "@src/firebaseConfig";
import {
  signOut as firebaseSignOut,
  updatePassword as firebaseUpdatePassword,
  reauthenticateWithCredential as firebaseReauthenticateWithCredential,
  EmailAuthProvider,
  sendPasswordResetEmail,
} from "firebase/auth";

export async function getIdToken() {
  const { currentUser } = auth;
  return await currentUser?.getIdToken();
}

export function signOut() {
  return firebaseSignOut(auth);
}

export async function updatePassword(newPassword: string) {
  const user = auth.currentUser;
  if (!user) throw new Error("No current user.");
  return firebaseUpdatePassword(user, newPassword);
}
export async function reauthenticateWithCredential(currentPassword: string) {
  const user = auth.currentUser;
  if (!user?.email) throw new Error("No current user or email.");
  const credential = EmailAuthProvider.credential(user.email, currentPassword);
  return firebaseReauthenticateWithCredential(user, credential);
}

export async function sendPasswordResetEmailWhenSignedIn(continueUrl: string) {
  const { email } = auth.currentUser ?? {};
  if (!email) throw new Error("Invalid email address");
  return sendPasswordResetEmail(auth, email, { url: continueUrl });
}
