// Allow import here - this is the root request file
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import superagent, { SuperAgentRequest } from "superagent";
import Cookies from "js-cookie";
// Use getters for unit tests
import {
  VITE_ACTIVITY_LOGS_BASE_URL,
  VITE_ARCADIA_BASE_URL,
  VITE_BACKPACK_URL,
  VITE_ENABLE_UNSAFE_REQUESTS,
  VITE_LEDGER_BASE_URL,
  VITE_REPORTER_BASE_URL,
  VITE_SITE_SERVICE_BASE_URL,
  VITE_TENANTS_AND_SPACES_BASE_URL,
  VITE_UPLOAD_BASE_URL,
  VITE_UTILITIES_BASE_URL,
  VITE_MARKETPLACE_BASE_URL,
  VITE_PORTABLE_DOCUMENT_FORMAT_BASE_URL,
  VITE_AUTH_SERVICE_BASE_URL,
  VITE_EMAIL_SERVICE_BASE_URL,
  VITE_REWARDS_SANDBOX_BASE_URL,
} from "../env";

import { getIdToken } from "@src/auth/utils";
/**
 * Pretty much everything in this file is written as a create function, so that we can mock the env variables in tests.
 * The actual value or function is then exported using the real env variables as the argument.
 */

const siteUrl = { VITE_BACKPACK_URL };

const serviceUrls = {
  VITE_REPORTER_BASE_URL,
  VITE_LEDGER_BASE_URL,
  VITE_UPLOAD_BASE_URL,
  VITE_SITE_SERVICE_BASE_URL,
  VITE_TENANTS_AND_SPACES_BASE_URL,
  VITE_UTILITIES_BASE_URL,
  VITE_ACTIVITY_LOGS_BASE_URL,
  VITE_ARCADIA_BASE_URL,
  VITE_MARKETPLACE_BASE_URL,
  VITE_PORTABLE_DOCUMENT_FORMAT_BASE_URL,
  VITE_AUTH_SERVICE_BASE_URL,
  VITE_EMAIL_SERVICE_BASE_URL,
  VITE_REWARDS_SANDBOX_BASE_URL,
} as const;

const backendUrls = {
  ...serviceUrls,
  ...siteUrl,
};

const env = {
  VITE_MODE: import.meta.env.MODE,
  VITE_ENABLE_UNSAFE_REQUESTS,
  ...backendUrls,
} as const;

// This is the base url that the client is hosted from, as well as some other legacy services
export const prefixUrl = VITE_BACKPACK_URL;

const createAllUrlEnvsMap = (_env: typeof env) => {
  return {
    VITE_BACKPACK_URL: _env.VITE_BACKPACK_URL,
    VITE_MARKETPLACE_BASE_URL: _env.VITE_MARKETPLACE_BASE_URL,
    VITE_PORTABLE_DOCUMENT_FORMAT_BASE_URL:
      _env.VITE_PORTABLE_DOCUMENT_FORMAT_BASE_URL,
    VITE_AUTH_SERVICE_BASE_URL: _env.VITE_AUTH_SERVICE_BASE_URL,
    VITE_EMAIL_SERVICE_BASE_URL: _env.VITE_EMAIL_SERVICE_BASE_URL,
    VITE_REWARDS_SANDBOX_BASE_URL: _env.VITE_REWARDS_SANDBOX_BASE_URL,
    VITE_REPORTER_BASE_URL: _env.VITE_REPORTER_BASE_URL,
    VITE_LEDGER_BASE_URL: _env.VITE_LEDGER_BASE_URL,
    VITE_UPLOAD_BASE_URL: _env.VITE_UPLOAD_BASE_URL,
    VITE_SITE_SERVICE_BASE_URL: _env.VITE_SITE_SERVICE_BASE_URL,
    VITE_TENANTS_AND_SPACES_BASE_URL: _env.VITE_TENANTS_AND_SPACES_BASE_URL,
    VITE_UTILITIES_BASE_URL: _env.VITE_UTILITIES_BASE_URL,
    VITE_ACTIVITY_LOGS_BASE_URL: _env.VITE_ACTIVITY_LOGS_BASE_URL,
    VITE_ARCADIA_BASE_URL: _env.VITE_ARCADIA_BASE_URL,
  } satisfies {
    [key in keyof typeof backendUrls]: string;
  };
};

const createAllUrlEnvsArray = (_env: typeof env) =>
  Object.values(createAllUrlEnvsMap(_env));

export const allUrlEnvsMap = createAllUrlEnvsMap(env);

const stagingUrls = ["bpn-staging.com"];

export const createEnvIsStaging = (_env: typeof env) => {
  return createAllUrlEnvsArray(_env).some((url) =>
    stagingUrls.some((domain) => url.includes(domain))
  );
};

export const envIsStaging = createEnvIsStaging(env);

const prodUrls = ["backpacknetworks.com"];

export const createEnvIsProd = (_env: typeof env) => {
  return createAllUrlEnvsArray(_env).some((url) =>
    prodUrls.some((domain) => url.includes(domain))
  );
};

export const envIsProd = createEnvIsProd(env);

export const createConsistentEnv = (_env: typeof env) => {
  return (
    createAllUrlEnvsArray(_env).every((url) =>
      stagingUrls.some((domain) => url.includes(domain))
    ) ||
    createAllUrlEnvsArray(_env).every((url) =>
      prodUrls.some((domain) => url.includes(domain))
    )
  );
};

export const consistentEnv = createConsistentEnv(env);

const createDomainsFromEnv = (_env: typeof env) => {
  return createAllUrlEnvsArray(_env).reduce((acc, url) => {
    // make array of all domains, then we can check the size of array
    const domain = new URL(url).hostname;
    acc.add(domain);
    return acc;
  }, new Set<string>());
};

export const domainsFromEnv = createDomainsFromEnv(env);

export const createIsInExpectedEnv = (_env: typeof env) => {
  return (
    (createEnvIsStaging(_env) && _env.VITE_MODE === "staging") ||
    (createEnvIsProd(_env) && _env.VITE_MODE === "production")
  );
};

export function createRequestShouldThrow(_env: typeof env) {
  return function (options?: {
    safe?: boolean;
    prodOnly?: boolean;
    isTestSite?: boolean;
  }) {
    const { safe, prodOnly, isTestSite } = options || {};
    // Should throw if prod only condition is violated
    if (prodOnly && !isTestSite && _env.VITE_MODE !== "production") {
      return "This request is only allowed in production or for Test Sites";
    }

    // If request is safe, or we have enabled unsafe requests, we can skip this block.
    if (!safe && !_env.VITE_ENABLE_UNSAFE_REQUESTS) {
      /**
       * Before throwing, we want to additionally checking that the app is
       * operating in intended environment - i.e. envIsProd is only an issue
       * if we are not in production.
       */
      if (!createIsInExpectedEnv(_env)) {
        return "Unsafe requests are not enabled.";
      }
    }

    return null;
  };
}

export const requestShouldThrow = createRequestShouldThrow(env);

// Prefixes relative urls and prevents unsafe requests
export const createSuperagentPlugin = (_env: typeof env) => {
  return function (options?: {
    safe?: boolean;
    prodOnly?: boolean;
    isTestSite?: boolean;
  }) {
    return function (r: SuperAgentRequest) {
      try {
        // Relative URLs - should be prefixed with the defaultBackendUrl
        if (r.url[0] === "/") {
          r.url = prefixUrl + r.url;
        }

        const errorMessage = createRequestShouldThrow(_env)(options);
        if (errorMessage) throw new Error(errorMessage);

        return r;
      } catch (e: any) {
        // eslint-disable-next-line
        console.error(r.method, r.url, e);
        r.url = "";
        return r;
      }
    };
  };
};

const superagentPlugin = createSuperagentPlugin(env);

type CallbackHandler = (err: any, res: superagent.Response) => void;

// regular plugin doesn't work for this
// https://github.com/ladjs/superagent/issues/982
const withToken = (r: SuperAgentRequest) => {
  const _end = r.end;
  const promise = getIdToken();
  r.end = (...args) => {
    promise
      .then((token) => {
        r.set("Authorization", `Bearer ${token ?? Cookies.get("jwt")}`); // PDF service sets JWT cookie in headless browser from bearer token in request
        _end.apply(r, args);
      })
      // eslint-disable-next-line
      .catch((e) => console.error("Authorization failed" + e));
  };
  return r;
};

const request = {
  // Safe methods
  get: (url: string, callback?: CallbackHandler) =>
    superagent
      .get(url, callback)
      .use(superagentPlugin({ safe: true }))
      .use(withToken),
  options: (url: string, callback?: CallbackHandler) =>
    superagent
      .options(url, callback)
      .use(superagentPlugin({ safe: true }))
      .use(withToken),
  /** post_safe is a variation primarly to cover `post` requests which do not result in database mutations. For example when requesting data but a request body is required.  */
  post_safe: (url: string, callback?: CallbackHandler) =>
    superagent
      .post(url, callback)
      .use(superagentPlugin({ safe: true }))
      .use(withToken),
  // Unsafe methods
  /** The `post` function covers the default behaviours interacting with our database. Requests will work as normal in development, production and staging where the REACT_APP_BACKEND flag is NOT used.  */
  post: (url: string, callback?: CallbackHandler) =>
    superagent.post(url, callback).use(superagentPlugin()).use(withToken),
  /** post_prodOnly is a quite self-explanatory variation of `post`. This function should be used for prod-only interactions with 3rd party APIs where for example linking IDs need to be kept in our DB. */
  post_prodOnly: (
    url: string,
    callback?: CallbackHandler,
    isTestSite?: boolean
  ) =>
    superagent
      .post(url, callback)
      .use(superagentPlugin({ prodOnly: true, isTestSite }))
      .use(withToken),
  put: (url: string, callback?: CallbackHandler) =>
    superagent.put(url, callback).use(superagentPlugin()).use(withToken),
  delete: (url: string, callback?: CallbackHandler) =>
    superagent.delete(url, callback).use(superagentPlugin()).use(withToken),
  patch: (url: string, callback?: CallbackHandler) =>
    superagent.patch(url, callback).use(superagentPlugin()).use(withToken),
  merge: (url: string, callback?: CallbackHandler) =>
    superagent.merge(url, callback).use(superagentPlugin()).use(withToken),
};

export default request;
