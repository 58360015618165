import * as R from "ramda";
import request from "../request";

import {
  formatDataObj,
  formatDataArray,
  camelCaseCipher,
  snakeCaseCipher,
} from "./common";

import type { CapexProjectStatus } from "../../types";

const inboundProjectInputCipher = {
  id: "id",
  project_id: "projectId",
  project_accounting_type: "type",
  title: "title",
  description: "description",
  cost_type: "costType",
  current_operations: "subDescription",
  utility_name: "utilityProvider",
  expiration: "rebateExpiration",
  number_of_rebates: "rebateAmount",
  rebate_rate: "rebateRate",
  analysis: "analysis",
  total: "total",
  sub_project_id: "subProjectId",
  parent_accounting_id: "parentInputId",
  selected: "isSelected",
} as const;

const outboundProjectInputEncoder = R.invertObj(inboundProjectInputCipher);
delete outboundProjectInputEncoder.total;

const postAdditionalProjectOptOut = async (siteId: number, optOut: boolean) =>
  request.post(`/rest/additional_project_opt_out`).send({
    site_id: siteId,
    opt_out: optOut,
  });

export const getCapexProjectStatus = (
  siteId: string | number
): Promise<CapexProjectStatus> =>
  request
    .get(`/rest/project_capital_plan_complete`)
    .query({ site_id: `eq.${siteId}` })
    .then(({ body }) => formatDataArray(body, camelCaseCipher))
    .then((data) => data[0]);

export const updateCapexProjectStatus = (
  status: Partial<CapexProjectStatus>
): Promise<CapexProjectStatus> =>
  request
    .post(`/rest/project_capital_plan_complete`)
    .set("Prefer", "return=representation,resolution=merge-duplicates")
    .query({ on_conflict: "site_id" })
    .send(formatDataObj(status, snakeCaseCipher))
    .then(({ body }) => formatDataObj(body[0], camelCaseCipher));

export { postAdditionalProjectOptOut };
