import * as React from "react";
import classnames from "classnames";
import ResizeObserver from "react-resize-observer";
import Icon, { IconNames } from "../../icons/Icon/Icon";

import styles from "./TabButtonsGroup.module.scss";

import LinkWithQuery from "../../../../backpack-console/components/LinkWithQuery";
import { ClickableDiv } from "../../../utils/ClickableDiv";
import Text, { TextVariants } from "../../type/Text/Text";
import { startCase } from "lodash";

type Rect = {
  height: number;
  width: number;
  x: number;
  y: number;
};

/**
 * Variant changes...
 * primary-a -> tab_nav-primary /default
 * secondary-* -> tab_nav-secondary
 *
 * primary-a / alt -> tab_mod-a /default
 * primary-b / secondary-a -> tab_mod-b-*
 */

type IDType = string | number | boolean;

const navVariants = [
  "tab_nav-primary",
  "tab_nav-secondary",
  "tab_main",
] as const;

type NavigationVariantType = (typeof navVariants)[number];

const moduleVariants = [
  "tab_mod-a",
  "tab_mod-b-large",
  "tab_mod-b-medium",
  "tab_mod-b-small",
  "tab_mod-b-extraSmall",
  "tab_mod-c-small",
] as const;

export type ModuleVariantType = (typeof moduleVariants)[number];

type TabButtonGroupVariant = NavigationVariantType | ModuleVariantType;

export type TabButtonOption<OptionIDType> = {
  id: OptionIDType;
  label?: string;
  icon?: IconNames;
  iconProps?: Partial<React.ComponentPropsWithoutRef<typeof Icon>>;
  disabled?: boolean;
  nodeLabel?: React.ReactNode;
};

type TabButtonsGroupProps<OptionIDType> = {
  options:
    | Array<TabButtonOption<OptionIDType>>
    | ReadonlyArray<TabButtonOption<OptionIDType>>;
  onSelect?: (
    arg1: TabButtonOption<OptionIDType>,
    event?: React.MouseEvent<HTMLElement>
  ) => void;
  selected?: OptionIDType | null | false;
  className?: string;
  disabled?: boolean;
  navRoot?: string;
  queries?: string[];
} & (
  | {
      type?: "nav";
      variant?: NavigationVariantType;
    }
  | {
      type: "mod";
      variant?: ModuleVariantType;
    }
);

const defaultNavVariant = "tab_nav-primary";
const defaultModVariant = "tab_mod-a";

const GroupContext = React.createContext<{
  rects: Rect[];
  updateRects: (rect: Rect, index: number) => void;
  selectedIndex: number;
  type: "nav" | "mod";
  variant: TabButtonGroupVariant;
}>({
  rects: [],
  updateRects: () => {},
  selectedIndex: -1,
  type: "nav",
  variant: defaultNavVariant,
});

export default function TabButtonsGroup<OptionIDType extends IDType>(
  props: TabButtonsGroupProps<OptionIDType>
) {
  const {
    options,
    onSelect,
    selected,
    type = "nav",
    variant = type === "nav" ? defaultNavVariant : defaultModVariant,
    className,
    disabled = false,
    navRoot,
    queries,
  } = props;

  const selectedIndex = options?.findIndex((option) => option.id === selected);

  const [rects, setRects] = React.useState<Rect[]>([]);

  const updateRects = React.useCallback((rect: Rect, index: number) => {
    setRects((prev) => ({ ...prev, [index]: rect }));
  }, []);

  const ctx = React.useMemo(
    () => ({
      type,
      variant,
      rects,
      updateRects,
      selectedIndex,
    }),
    [rects, updateRects, selectedIndex, type, variant]
  );

  const outerContainerClasses = React.useMemo(() => {
    return classnames("relative flex flex-row overflow-visible", {
      "disabled pointer-events-none cursor-not-allowed opacity-25": disabled,
      "h-9.5 bg-straps-accent-3": variant === "tab_mod-a",
      "h-10":
        variant === "tab_mod-b-medium" ||
        variant === "tab_mod-b-large" ||
        variant === "tab_mod-b-small" ||
        variant === "tab_mod-b-extraSmall" ||
        variant === "tab_mod-c-small",
      "h-12": variant === "tab_nav-primary",
      "h-[54px]": variant === "tab_mod-b-large",
      "h-15": variant === "tab_nav-secondary",
      "w-full":
        variant === "tab_mod-b-medium" ||
        variant === "tab_mod-b-extraSmall" ||
        variant === "tab_mod-b-large",
      "bg-pure-white":
        variant === "tab_nav-primary" ||
        variant === "tab_mod-b-medium" ||
        variant === "tab_mod-b-large" ||
        variant === "tab_mod-b-small" ||
        variant === "tab_mod-b-extraSmall" ||
        variant === "tab_mod-c-small",
      "shadow-[0_10px_20px_rgba(0,29,35,0.12)]": variant === "tab_mod-b-small",
    });
  }, [variant, disabled]);

  React.useEffect(() => {
    // workaround to get the ResizeObserver in the `TabButton` to update `rects` state when the number of tabs changes
    // This happens often when a tab is behind a feature flag, and query params are used to override the feature flags value
    // https://github.com/bootstarted/react-resize-observer#position-detection
    document.body.dispatchEvent(new UIEvent("scroll"));
  }, [options]);

  return (
    <nav className={classnames(outerContainerClasses, className)}>
      <GroupContext.Provider value={ctx}>
        <TabGroup
          options={options}
          selectedIndex={selectedIndex}
          onSelect={onSelect}
          navRoot={navRoot}
          queries={queries}
        />
      </GroupContext.Provider>
    </nav>
  );
}

interface TabProps<OptionIDType> {
  options:
    | Array<TabButtonOption<OptionIDType>>
    | ReadonlyArray<TabButtonOption<OptionIDType>>;
  selectedIndex: number;
  onSelect?: (
    arg1: TabButtonOption<OptionIDType>,
    event?: React.MouseEvent<HTMLElement>
  ) => void;
  navRoot?: string;
  queries?: string[];
}

const TabGroup = <OptionIDType extends IDType>({
  options,
  selectedIndex,
  onSelect,
  navRoot,
  queries,
}: TabProps<OptionIDType>) => {
  const { type, variant } = React.useContext(GroupContext);

  return (
    <>
      {variant === "tab_mod-b-small" && (
        <ArrowButton
          direction="left"
          onSelect={onSelect}
          options={options}
          selectedIndex={selectedIndex}
        />
      )}
      <div
        className={classnames(
          "flex h-full flex-1 flex-row items-center justify-between",
          {
            relative: type === "mod",
          }
        )}
      >
        {selectedIndex > -1 && variant !== "tab_mod-a" && <TabActiveRect />}
        {options?.map((option, index) => {
          const isSelected = selectedIndex === index;

          return (
            <React.Fragment key={option.id.toString()}>
              <>
                <TabButton
                  navRoot={navRoot}
                  option={option}
                  index={index}
                  isSelected={isSelected}
                  onSelect={onSelect}
                  queries={queries}
                />
                {index < options?.length - 1 && (
                  <TabDivider
                    hide={isSelected || selectedIndex - 1 === index}
                  />
                )}
              </>
            </React.Fragment>
          );
        })}
      </div>
      {variant === "tab_mod-b-small" && (
        <ArrowButton
          direction="right"
          onSelect={onSelect}
          options={options}
          selectedIndex={selectedIndex}
        />
      )}
    </>
  );
};

function ArrowButton(
  props: Readonly<{
    direction: "left" | "right";
    selectedIndex: number;
    options: Array<TabButtonOption<any>> | ReadonlyArray<TabButtonOption<any>>;
    onSelect?: (option: TabButtonOption<any>) => void;
  }>
) {
  const { direction, selectedIndex, options, onSelect } = props;

  const handleChangeTab = () => {
    if (direction === "right") {
      for (let index = selectedIndex + 1; index < options.length; index += 1) {
        if (!options[index]!.disabled) {
          onSelect && onSelect(options[index]!);
          return;
        }
      }
    } else {
      for (let index = selectedIndex - 1; index >= 0; index -= 1) {
        if (!options[index]!.disabled) {
          onSelect && onSelect(options[index]!);
          return;
        }
      }
    }
  };

  return (
    <ClickableDiv
      className={classnames(
        "group flex h-full w-6 cursor-pointer flex-row items-center justify-center bg-pure-white transition",
        {
          "border-r": direction === "left",
          "border-l": direction === "right",
        }
      )}
      style={{
        borderColor: "rgba(187, 187, 187, 0.25)",
      }}
      onClick={handleChangeTab}
    >
      <div className="flex h-3 w-3 items-center justify-center">
        <Icon
          name={direction === "left" ? "caret-left" : "caret-right"}
          size="small"
          className="text-straps-tertiary transition group-hover:text-straps-secondary"
        />
      </div>
    </ClickableDiv>
  );
}

type TabButtonProps<OptionIDType> = {
  option: TabButtonOption<OptionIDType>;
  index: number;
  onSelect?: (
    arg1: TabButtonOption<OptionIDType>,
    event?: React.MouseEvent<HTMLElement>
  ) => void;
  navRoot?: string;
  queries?: string[];
  isSelected: boolean;
};

function TabButtonOrLink(
  props: Readonly<{
    type: "nav" | "mod" | undefined;
    optionId: string;
    navRoot: string | undefined;
    queries?: string[];
    children: React.ReactNode;
    className: string;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
  }>
) {
  const { type, optionId, navRoot, queries, ...rest } = props;
  if (type === "nav") {
    return (
      <LinkWithQuery
        data-testid={`link-to-${optionId}`}
        to={`${navRoot || ""}/${optionId}`}
        queries={[...(queries ?? []), "accountId"]}
        {...rest}
      >
        {props.children}
      </LinkWithQuery>
    );
  }
  return <button {...rest}>{props.children}</button>;
}

const titleTextVariantMap: Record<
  ModuleVariantType | NavigationVariantType,
  TextVariants
> = {
  "tab_mod-c-small": "sb_t-12-500",
  "tab_mod-b-extraSmall": "sb_t-10-500",
  "tab_mod-b-small": "sa_t-12-700",
  "tab_mod-b-medium": "sa_t-14-700",
  "tab_mod-b-large": "h5_t-18-700",
  "tab_mod-a": "sa_t-12-700",
  "tab_nav-primary": "sa_t-14-700",
  "tab_nav-secondary": "sa_t-14-700",
  tab_main: "sa_t-14-700",
};

const iconSizeVariantMap: Record<
  ModuleVariantType | NavigationVariantType,
  React.ComponentPropsWithoutRef<typeof Icon>["size"]
> = {
  "tab_mod-c-small": "small",
  "tab_mod-b-extraSmall": "small",
  "tab_mod-b-small": "medium",
  "tab_mod-b-medium": "medium",
  "tab_mod-b-large": "large",
  "tab_mod-a": "medium",
  "tab_nav-primary": "large",
  "tab_nav-secondary": "large",
  tab_main: "large",
};

function TabButton<OptionIDType extends IDType>({
  option,
  index,
  queries,
  onSelect,
  navRoot,
  isSelected,
}: Readonly<TabButtonProps<OptionIDType>>) {
  const { updateRects, type, variant } = React.useContext(GroupContext);

  const { disabled } = option;
  const roundedVariant =
    variant === "tab_main" || variant === "tab_nav-secondary";

  const tab_modB =
    variant === "tab_mod-b-large" ||
    variant === "tab_mod-b-medium" ||
    variant === "tab_mod-b-small" ||
    variant === "tab_mod-b-extraSmall";

  const tab_modC = variant === "tab_mod-c-small";

  return (
    <TabButtonOrLink
      type={type}
      optionId={option.id.toString()}
      navRoot={navRoot}
      queries={queries}
      onClick={(e: React.MouseEvent<HTMLElement>) =>
        !option.disabled && onSelect && onSelect(option, e)
      }
      className={classnames(
        styles.tabButton,
        "group relative z-0 flex h-full items-center justify-center bg-transparent transition focus-visible:outline-straps-hyperlink-hover",
        {
          "border-2 border-straps-hyperlink/0 focus-visible:border-straps-hyperlink":
            type === "nav",
          "gap-x-2.5 px-[30px]":
            type === "mod" &&
            variant !== "tab_mod-b-extraSmall" &&
            variant !== "tab_mod-c-small",
          "gap-x-2.5 px-10": variant === "tab_mod-c-small",
          "gap-x-1 px-2": variant === "tab_mod-b-extraSmall",
          "cursor-pointer": !disabled,
          "pointer-events-none cursor-not-allowed opacity-25": disabled,
          // tab_nav-primary
          "gap-x-3 px-[30px] py-3.5": variant === "tab_nav-primary",
          // tab_nav-secondary-*
          "flex-grow gap-x-3.5 rounded-full p-[18px]": roundedVariant,
          "flex-1":
            variant === "tab_mod-c-small" ||
            variant === "tab_mod-b-extraSmall" ||
            variant === "tab_mod-b-medium" ||
            variant === "tab_mod-b-large",
          "!bg-pure-white": isSelected && variant === "tab_mod-a",
          "!bg-straps-accent-3": !isSelected && variant === "tab_mod-a",
        }
      )}
    >
      <ResizeObserver onReflow={(rect) => updateRects(rect, index)} />
      {option.icon && (
        <div
          className={classnames("flex items-center justify-center transition", {
            "h-5 w-5": variant === "tab_nav-primary",
            "h-6 w-6": roundedVariant,
          })}
        >
          <Icon
            name={option.icon}
            size={iconSizeVariantMap[variant]}
            className={classnames(
              type === "nav"
                ? {
                    // tab_nav-primary
                    "text-straps-positive":
                      isSelected && variant === "tab_nav-primary",
                    "text-straps-secondary group-hover:text-straps-positive":
                      !isSelected && variant === "tab_nav-primary",
                    // tab_nav-secondary-*
                    "text-straps-primary group-hover:text-straps-primary":
                      isSelected && roundedVariant,
                    "text-straps-secondary group-hover:text-straps-primary":
                      !isSelected && roundedVariant,
                  }
                : {
                    "text-straps-primary": isSelected,
                    "text-straps-tertiary group-hover:text-straps-primary":
                      !isSelected &&
                      (tab_modB || tab_modC || variant === "tab_mod-a"),
                  }
            )}
            {...option.iconProps}
          />
        </div>
      )}
      <Text
        title={option.label || startCase(option.id.toString())}
        variant={titleTextVariantMap[variant]}
        color={isSelected ? "primary" : "secondary"}
        className={classnames(
          "w-max group-hover:text-straps-primary",
          styles.titleContainer,
          {
            "pt-px uppercase": variant === "tab_mod-a",
            "font-bold": variant === "tab_mod-c-small" && isSelected,
          }
        )}
      >
        {option.nodeLabel || option.label || startCase(option.id.toString())}
      </Text>
    </TabButtonOrLink>
  );
}

function TabDivider({ hide }: { readonly hide?: boolean }) {
  const { variant } = React.useContext(GroupContext);
  const roundedVariant =
    variant === "tab_main" || variant === "tab_nav-secondary";

  return (
    <div
      className={classnames("w-px min-w-[1px] bg-straps-accent-3", {
        "bg-straps-tertiary": variant === "tab_mod-a",
        "transition-opacity": roundedVariant,
        "opacity-0": hide && (roundedVariant || variant === "tab_mod-a"),
        "h-5": variant === "tab_nav-primary" || variant === "tab_mod-a",
        "h-8.5": variant === "tab_nav-secondary",
        "h-6":
          variant === "tab_main" ||
          variant === "tab_mod-b-medium" ||
          variant === "tab_mod-b-small" ||
          variant === "tab_mod-b-extraSmall" ||
          variant === "tab_mod-c-small",
        "h-[30px]": variant === "tab_mod-b-large",
      })}
    />
  );
}

function TabActiveRect() {
  const { rects, selectedIndex, variant } = React.useContext(GroupContext);
  let selectedOffset = 0;
  let selectedWidth: number = rects[0] ? rects[0].width : 0;

  if (selectedIndex > -1 && rects[0]! && rects[selectedIndex]!) {
    selectedOffset = rects[selectedIndex]!.x - rects[0]!.x;
    selectedWidth = rects[selectedIndex]!.width;
  }

  return (
    <div
      className={classnames("absolute left-0 transition-all", {
        "bottom-0 h-0.5 bg-straps-positive":
          variant === "tab_nav-primary" ||
          variant === "tab_mod-b-large" ||
          variant === "tab_mod-b-medium" ||
          variant === "tab_mod-b-small" ||
          variant === "tab_mod-b-extraSmall",
        "bottom-0 h-0.5 bg-straps-primary": variant === "tab_mod-c-small",
        "top-0 h-full rounded-full bg-pure-white shadow-[0_10px_20px_rgba(0,29,35,0.12)]":
          variant === "tab_main" || variant === "tab_nav-secondary",
      })}
      style={{
        transform: `translateX(${selectedOffset}px)`,
        width: selectedWidth,
      }}
    />
  );
}
