import { useQuery as useReactQuery } from "react-query";
import FetchClients, { ClientPaths, ClientOutput } from "../client";

export type SummaryUtilitiesReportQuery =
  ClientPaths["utilities"]["/reports/summary_utilities"]["get"]["parameters"]["query"];
export type SummaryUtilitiesReport =
  ClientOutput["utilities"]["SummaryUtilityUsageReport"];
export type DatePeriod = ClientOutput["utilities"]["DatePeriod"];

const reports = {
  useSummaryUtilities,
} as const;

export default reports;

const SUMMARY_QUERY_KEY = "summary-utilities-report";
function useSummaryUtilities(query: SummaryUtilitiesReportQuery) {
  return useReactQuery({
    queryKey: [SUMMARY_QUERY_KEY, query],
    queryFn: async () => {
      const { data, error } = await FetchClients.utilities.GET(
        "/reports/summary_utilities",
        {
          params: {
            query,
          },
        }
      );
      if (data) return data;
      throw new Error(error);
    },
  });
}
