import classNames from "classnames";
import { useEffect, useState } from "react";
import {
  VITE_MOCK_SERVICE_WORKER,
  VITE_ENABLE_UNSAFE_REQUESTS,
  VITE_DISABLE_SUPER_ADMIN_IN_CLIENT,
  VITE_APP_VERSION,
} from "./env";
import {
  allUrlEnvsMap,
  consistentEnv,
  domainsFromEnv,
  envIsProd,
  envIsStaging,
} from "./global_functions/request";
import { ClickableDiv } from "@src/straps/utils/ClickableDiv";
import Icon from "@src/straps/base/icons/Icon/Icon";
import { Modal } from "./straps/base";
import { atom, useAtom, useSetAtom } from "jotai";

const debugBannerClosedAtom = atom(false);

export const CloseDebugBanner = () => {
  const setClosed = useSetAtom(debugBannerClosedAtom);
  useEffect(() => {
    setClosed(true);
  }, [setClosed]);

  return null;
};

const DebugBanner = () => {
  const [closed, setClosed] = useAtom(debugBannerClosedAtom);
  if ((envIsProd && import.meta.env.MODE === "production") || closed) {
    return null;
  }

  const enabledFlags = (
    [
      ["VITE_ENABLE_UNSAFE_REQUESTS", VITE_ENABLE_UNSAFE_REQUESTS],
      ["VITE_MOCK_SERVICE_WORKER", VITE_MOCK_SERVICE_WORKER],
      [
        "VITE_DISABLE_SUPER_ADMIN_IN_CLIENT",
        VITE_DISABLE_SUPER_ADMIN_IN_CLIENT,
      ],
    ] as const
  )
    .filter(([, enabled]) => enabled)
    .map(([name]) => name);

  return (
    <div
      className={classNames("warning-banner", {
        unsafe: VITE_ENABLE_UNSAFE_REQUESTS,
      })}
    >
      <div className="flex justify-between">
        <div>
          {enabledFlags.length > 0 && (
            <span>
              You have enabled <b>{enabledFlags.join(", ")}</b>.{" "}
            </span>
          )}
          This instance of{" "}
          <b>
            {" "}
            {"Backpack"} (mode: {import.meta.env.MODE}){" "}
          </b>{" "}
          is pointing at <PointingAt />
        </div>
        <div className="items-base flex gap-2">
          {VITE_APP_VERSION}{" "}
          <ClickableDiv
            onClick={() => setClosed(true)}
            className="h-2 cursor-pointer"
          >
            <Icon name="close" size="small" className="scale-75" />
          </ClickableDiv>
        </div>
      </div>
    </div>
  );
};

function PointingAt() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  if (!consistentEnv)
    return (
      <>
        <ClickableDiv
          className="cursor-pointer"
          onClick={() => setIsModalOpen(true)}
        >
          <b>inconsistent domains</b> - {[...domainsFromEnv].join(", ")}.
        </ClickableDiv>
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <Modal.Header title="Inconsistent Domains in Environment" />
          <div className="m-4">
            {Object.entries(allUrlEnvsMap).map(([envKey, url]) => (
              <pre key={envKey}>
                {envKey}: {url}
              </pre>
            ))}
          </div>
        </Modal>
      </>
    );

  if (envIsProd) return <b>Production.</b>;

  if (envIsStaging) return <b>Staging.</b>;

  return <b>Unknown.</b>;
}

export default DebugBanner;
