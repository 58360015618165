import { recursiveCamelCaseCipher } from "../../postgrestApi/common";
import { CamelCasedProperties } from "type-fest";
import {
  useMutation,
  useQuery as useReactQuery,
  useQueryClient,
} from "react-query";
import { tenantsAndSpacesKeys } from "../../fortyTwoApi/tenantsAndSpaces/queryKeyFactory";
import FetchClients, {
  ClientOutput,
} from "@src/global_functions/backpackSdk/client";

type EffectiveDate = CamelCasedProperties<
  ClientOutput["tenantsAndSpaces"]["EffectiveDate"]
>;

const effectiveDate = {
  useQuery,
  mutations: {
    usePut,
  },
} as const;

export default effectiveDate;

const getEffectiveDate = async (
  site_id: number,
  building_id?: number | null
) => {
  if (typeof building_id !== "number")
    throw new Error("Building ID must be a number");
  const { data, error } = await FetchClients.tenantsAndSpaces.GET(
    "/{site_id}/effective_date/{building_id}",
    {
      params: {
        path: {
          site_id,
          building_id,
        },
      },
    }
  );
  if (data) return recursiveCamelCaseCipher(data) as EffectiveDate;
  throw new Error(error);
};

function useQuery(siteId: number, buildingId?: number | null) {
  const queryKey = tenantsAndSpacesKeys.query(
    siteId,
    "effectiveDate",
    `${buildingId}`
  );
  return useReactQuery({
    queryKey,
    queryFn: () => getEffectiveDate(siteId, buildingId),
    enabled: typeof buildingId === "number",
  });
}

// input needs to match format YYYY-(M|MM)-(D|DD)
const putEffectiveDate = async (
  site_id: number,
  building_id: number,
  input: EffectiveDate["effectiveDate"]
) => {
  const { response, error } = await FetchClients.tenantsAndSpaces.PUT(
    "/{site_id}/effective_date/{building_id}",
    {
      params: {
        path: {
          site_id,
          building_id,
        },
      },
      body: {
        effective_date: input,
      },
    }
  );
  if (response.ok) return;
  throw new Error(error);
};

function usePut(siteId: number, buildingId?: number | null) {
  const queryKey = tenantsAndSpacesKeys.query(
    siteId,
    "effectiveDate",
    `${buildingId}`
  );
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (input: EffectiveDate["effectiveDate"]) => {
      if (typeof buildingId !== "number")
        throw new Error("Building ID must be a number");
      return putEffectiveDate(siteId, buildingId, input);
    },
    onSuccess: () => queryClient.invalidateQueries(queryKey),
  });
}
