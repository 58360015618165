import FetchClients, { ClientOutput } from "../client";
import {
  useMutation,
  useQuery as useReactQuery,
  useQueryClient,
} from "react-query";
import { CamelCasedProperties } from "type-fest";
import {
  recursiveCamelCaseCipher,
  recursiveSnakeCaseCipher,
} from "@src/global_functions/postgrestApi";
import { BackpackServiceError, isBackpackResponseErrorBody } from "../errors";
import { retryIfNot404 } from "@src/global_functions/fortyTwoApi/reactQueryUtils";
import { NotFoundError } from "./util";

const unitTypes = {
  useQuery,
  useQueryPhoto,
  mutations: { useDelete, useUpsert, usePostPhoto, useDeletePhoto },
} as const;
export default unitTypes;

export type UnitType = CamelCasedProperties<
  ClientOutput["tenantsAndSpaces"]["UnitType"]
>;

export const UNIT_TYPES_QUERY_KEY = "unit_types";
const UNIT_TYPE_PHOTOS_QUERY_KEY = "unit_type_photos";

const getUnitTypes = async (site_id: number) => {
  const { response, error, data } = await FetchClients.tenantsAndSpaces.GET(
    "/{site_id}/unit_types",
    {
      params: {
        path: {
          site_id,
        },
      },
    }
  );
  if (response.ok) return recursiveCamelCaseCipher(data ?? []) as UnitType[];
  throw new Error(error);
};

function useQuery(siteId: number) {
  return useReactQuery({
    queryFn: () => getUnitTypes(siteId),
    queryKey: [UNIT_TYPES_QUERY_KEY, siteId],
  });
}

const upsertUnitType = async (
  site_id: number,
  { siteId: _, ...unitType }: UnitType
) => {
  const { data, error } = await FetchClients.tenantsAndSpaces.PUT(
    "/{site_id}/unit_types",
    {
      params: {
        path: {
          site_id,
        },
      },
      body: {
        site_id,
        ...recursiveSnakeCaseCipher(unitType),
      },
    }
  );
  if (data) return data;
  throw new Error(error);
};

function useUpsert(siteId: number) {
  return useMutation((unitType: UnitType) => upsertUnitType(siteId, unitType));
}

const deleteUnitType = async (site_id: number, unit_type_id: number) => {
  const { response, error } = await FetchClients.tenantsAndSpaces.DELETE(
    "/{site_id}/unit_types/{unit_type_id}",
    {
      params: {
        path: {
          site_id,
          unit_type_id,
        },
      },
    }
  );
  if (response.ok) return;

  if (isBackpackResponseErrorBody(error)) {
    throw new BackpackServiceError(error);
  }

  throw new Error(
    `Failed to delete unit type: ${
      (error as unknown as Error)?.message || "Unknown Error"
    }`
  );
};
function useDelete(siteId: number) {
  const queryClient = useQueryClient();
  return useMutation(
    (unitTypeId: number) => deleteUnitType(siteId, unitTypeId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([UNIT_TYPES_QUERY_KEY, siteId]),
    }
  );
}

const postPhoto = async (site_id: number, unit_type_id: number, file: File) => {
  const { response, error } = await FetchClients.d3.POST(
    "/unit-type-photo/upload",
    {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      params: {
        query: {
          site_id,
          unit_type_id,
          filename: file.name,
        },
      },
      // @ts-expect-error https://bractlet.atlassian.net/browse/INNO-786
      body: file,
      bodySerializer: (body) => body,
    }
  );
  if (response.ok) return;
  throw new Error(error);
};

function usePostPhoto(siteId: number) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ unitTypeId, file }: { unitTypeId: number; file: File }) =>
      postPhoto(siteId, unitTypeId, file),
    {
      onSuccess: (_, { unitTypeId }) =>
        queryClient.invalidateQueries([UNIT_TYPE_PHOTOS_QUERY_KEY, unitTypeId]),
    }
  );
}

const deletePhoto = async (unit_type_id: number) => {
  const { response, error } = await FetchClients.d3.DELETE(
    "/unit-type-photo/delete",
    {
      params: {
        query: {
          unit_type_id,
        },
      },
    }
  );
  if (response.ok) return;
  throw new Error(error);
};

function useDeletePhoto() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ unitTypeId }: { removeQueries?: boolean; unitTypeId: number }) =>
      deletePhoto(unitTypeId),
    {
      onSuccess: (_, { unitTypeId, removeQueries = true }) => {
        if (removeQueries) {
          queryClient.removeQueries([UNIT_TYPE_PHOTOS_QUERY_KEY, unitTypeId]);
        }
      },
    }
  );
}

const getPhoto = async (unit_type_id?: number | null) => {
  if (typeof unit_type_id !== "number")
    throw new Error("Unit type ID must be a number");
  const { data, response, error } = await FetchClients.d3.GET(
    "/unit-type-photo/download",
    {
      parseAs: "text",
      params: {
        query: {
          unit_type_id,
          disposition: "Inline",
        },
      },
    }
  );
  if (data) return data;
  if (response.status === 404) {
    throw new NotFoundError(`No photo found for ${unit_type_id}`);
  }
  throw new Error(error);
};

function useQueryPhoto(unitTypeId?: number | null) {
  return useReactQuery(
    [UNIT_TYPE_PHOTOS_QUERY_KEY, unitTypeId],
    () => getPhoto(unitTypeId),
    {
      retry: retryIfNot404,
      refetchOnWindowFocus: false,
      enabled: typeof unitTypeId === "number",
    }
  );
}
