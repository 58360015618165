import { camelCase, snakeCase } from "lodash";
import request from "../request";

import {
  bugsnagPostgrestErrorHandler,
  camelCaseCipher,
  formatDataObj,
  recursiveCamelCaseCipher,
  snakeCaseCipher,
} from "./common";

import type {
  Utility,
  UtilityAccount,
  UtilityAccountBase,
  UtilityOptOut,
} from "../../types";

import { UtilityAccountsOptedOutBySite } from "@src/backpack-console/pages/EnvironmentalImpact/EmissionsSummaryDownloadButton";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  UtilityCompleteItem,
  utilityCompleteItemToUtility,
} from "../backpackSdk/postgrest/onboardingComplete";
import backpackSdk from "../backpackSdk/sdk";
import { useInvalidateUtilityTypesQuery } from "../fortyTwoApi/site/hooks/useGetUtilityTypes";
import {
  addBackpackUtilityManual,
  addBackpackUtilityOptOut,
  updateBackpackUtilityManual,
  updateBackpackUtilityOptOut,
} from "./sites";

export const encryptPassword = (password: string | null | undefined) =>
  request
    .get(`/api/encrypt_utility_account_password`)
    .query({ value: password })
    .then(({ body }) => body.value);

const getUtilityAccounts = (
  siteId: string | number
): Promise<Array<UtilityAccount>> =>
  request
    .get(`/rest/utility_accounts_summary`)
    .query({ site_id: `eq.${siteId}` })
    .then(({ body }) => body.map(recursiveCamelCaseCipher))
    .then((rows) =>
      // @ts-expect-error - TS7006 - Parameter 'row' implicitly has an 'any' type.
      rows.map((row) => ({
        ...row,
        encryptedPassword: row.password,
        password: null,
      }))
    )
    .catch(bugsnagPostgrestErrorHandler);

const getUtilityAccountsQueryKey = (siteId: number) => [
  "utilityAccounts",
  siteId,
];

// Returns utility accounts for all utility types
export const useAllUtilityAccountsQuery = (siteId: number) =>
  useQuery({
    queryKey: getUtilityAccountsQueryKey(siteId),
    queryFn: () => getUtilityAccounts(siteId),
  });

// Returns the utility accounts for a specific Utility type
export const useUtilityAccountsQuery = (siteId?: number, utility?: Utility) =>
  useQuery({
    queryKey: getUtilityAccountsQueryKey(siteId!),
    queryFn: () => getUtilityAccounts(siteId!),
    select: (utilityAccounts) =>
      (utilityAccounts || []).filter((utilityAccount) => {
        // combine water and wastwater accounts in the water/wastewater section
        if (utility === "water") {
          return (
            utilityAccount.utility === "water" ||
            utilityAccount.utility === "wasteWater"
          );
        }
        return utilityAccount.utility === utility;
      }),
    enabled: Boolean(siteId !== undefined && utility),
  });

export const useInvalidateUtilityAccountsQuery = () => {
  const queryClient = useQueryClient();
  return (siteId: number) =>
    queryClient.invalidateQueries(getUtilityAccountsQueryKey(siteId));
};

// Adds utility account with or updates utility account to status "optout"
export const useBackpackUtilityOptOutMutation = (
  siteId: number,
  utility: Utility
) => {
  const { data: utilityAccounts, isSuccess } = useUtilityAccountsQuery(
    siteId,
    utility
  );
  const invalidateUtilityAccountsQuery = useInvalidateUtilityAccountsQuery();
  const invalidateUtilityTypesQuery = useInvalidateUtilityTypesQuery();

  return useMutation(async () => {
    if (!isSuccess) {
      return;
    }
    if (utilityAccounts && utilityAccounts.length) {
      await updateBackpackUtilityOptOut(
        siteId,
        snakeCase(utility) as UtilityOptOut
      );
    } else {
      await addBackpackUtilityOptOut(
        siteId,
        snakeCase(utility) as UtilityOptOut
      );
    }

    invalidateUtilityAccountsQuery(siteId);
    invalidateUtilityTypesQuery(siteId);
  });
};

// Adds utility account with or updates utility account to status "manual"
export const useBackpackUtilityManualMutation = (
  siteId?: number,
  utility?: Utility
) => {
  const { data: utilityAccounts, isSuccess } = useUtilityAccountsQuery(
    siteId,
    utility
  );
  const invalidateUtilityAccountsQuery = useInvalidateUtilityAccountsQuery();
  const invalidateUtilityTypesQuery = useInvalidateUtilityTypesQuery();

  return useMutation(
    async (
      variables?: { providerName?: string; providerId?: number | null } | void
    ) => {
      const { providerId, providerName } = variables ?? {};
      if (!isSuccess || siteId === undefined || !utility) {
        return;
      }
      if (utilityAccounts && utilityAccounts.length) {
        await updateBackpackUtilityManual(
          siteId,
          utility,
          providerName,
          providerId
        );
      } else {
        await addBackpackUtilityManual(
          siteId,
          utility,
          providerName,
          providerId
        );
      }
      invalidateUtilityAccountsQuery(siteId);
      invalidateUtilityTypesQuery(siteId);
    }
  );
};

export const useGetUtilityAccountsOptOutStatus = (siteIds: number[]) => {
  return backpackSdk.postgrest.onboardingComplete.useQueryAll(
    siteIds,
    (data): UtilityAccountsOptedOutBySite =>
      data.reduce((acc, { item, siteId, optOut }) => {
        const utility =
          utilityCompleteItemToUtility[camelCase(item) as UtilityCompleteItem];

        if (utility) {
          if (!acc[siteId]) {
            acc[siteId] = {};
          }
          acc[siteId]![utility] = Boolean(optOut);
        }
        return acc;
      }, {} as UtilityAccountsOptedOutBySite)
  );
};

export const deleteUtilityAccount = (id: number): Promise<void> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<void>'.
  request
    .delete(`/rest/utility_accounts`)
    .query({ id: `eq.${id}` })
    .catch(bugsnagPostgrestErrorHandler);

export const createUtilityAccount = async ({
  account,
  meters,
  locations,
  fileObj,
}: {
  account: Partial<UtilityAccountBase>;
  meters?: Array<string>;
  locations?: Array<string>;
  fileObj?: {
    file: File | MediaSource | Blob;
    billFileName: string;
  };
}): Promise<void> => {
  const encryptedPassword = account.password
    ? await encryptPassword(account.password)
    : null;

  const accountBase = {
    ...formatDataObj(account, snakeCaseCipher),
    utility: snakeCase(account.utility),
    status: account.status || "submitted",
    password: encryptedPassword,
  } as const;

  const newAccount = await request
    .post(`/rest/utility_accounts`)
    .set("Prefer", "return=representation")
    .send(accountBase)
    .then(({ body }) => formatDataObj(body[0], camelCaseCipher))
    .catch(bugsnagPostgrestErrorHandler);

  if (newAccount?.id) {
    await Promise.all([
      ...(meters || []).map((meter_name) =>
        request
          .post(`/rest/utility_account_suggested_meters`)
          .send({ meter_name, utility_account_id: newAccount.id })
          .catch(bugsnagPostgrestErrorHandler)
      ),
      ...(locations || []).map((location) =>
        request
          .post(`/rest/utility_account_suggested_locations`)
          .send({ location, utility_account_id: newAccount.id })
          .catch(bugsnagPostgrestErrorHandler)
      ),
    ]);
  }
};

export const editUtilityAccountBase = async (
  accountId: number,
  updatedProps: Partial<UtilityAccountBase>
) => {
  const formattedProps = formatDataObj(updatedProps, snakeCaseCipher, {
    allowNulls: true,
  });
  if (formattedProps.password) {
    const encryptedPassword = await encryptPassword(updatedProps.password);
    formattedProps.password = encryptedPassword;
  }
  if (updatedProps.utility) {
    formattedProps.utility = snakeCase(updatedProps.utility);
  }
  return request
    .patch(`/rest/utility_accounts`)
    .query({ id: `eq.${accountId}` })
    .send(formattedProps)
    .catch(bugsnagPostgrestErrorHandler);
};
