import { useMutation, useQuery, useQueryClient } from "react-query";

import FetchClients, { ClientOutput } from "../client";

export type UtilityAccount =
  ClientOutput["utilities"]["UtilityAccountAutomatedBills"];

export type UtilityAccountRequest =
  ClientOutput["utilities"]["UtilityAccountRequest"];

export type UtilityAccountNumberRequest =
  ClientOutput["utilities"]["UtilityAccountNumberRequest"];

export type UtilityAccountNameRequest =
  ClientOutput["utilities"]["UtilityAccountNameRequest"];

export type AccountUtilityType =
  ClientOutput["utilities"]["AccountUtilityType"];

const accounts = {
  useQueryAll,
  usePostByAccountNumber,
  useInvalidateQueryAll,
  usePatch,
  useQueryUtilityTypes,
} as const;

export default accounts;

export const UTILITY_ACCOUNTS_KEY = "utilities/accounts";

function useQueryAll(siteId: number) {
  return useQuery<UtilityAccount[]>(
    [UTILITY_ACCOUNTS_KEY, siteId],
    async () => {
      const { data, error } = await FetchClients.utilities.GET(
        "/{site_id}/accounts",
        {
          params: {
            path: {
              site_id: siteId,
            },
          },
        }
      );
      if (data) return [...data];
      throw new Error(error);
    }
  );
}

// This is for creating third-party-portal accounts only
function usePostByAccountNumber(siteId: number) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (account: UtilityAccountNumberRequest) => {
      const { data, error } = await FetchClients.utilities.POST(
        "/{site_id}/accounts",
        {
          params: {
            path: {
              site_id: siteId,
            },
          },
          body: account,
        }
      );
      if (data) return data;
      throw new Error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([UTILITY_ACCOUNTS_KEY, siteId]);
    },
  });
}

function usePatch(siteId: number) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: { accountId: number; accountName: string }) => {
      const { data, error } = await FetchClients.utilities.PATCH(
        "/{site_id}/accounts/{account_id}",
        {
          params: {
            path: {
              site_id: siteId,
              account_id: params.accountId,
            },
          },
          body: {
            account_name: params.accountName,
          },
        }
      );
      if (data) return data;
      throw new Error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([UTILITY_ACCOUNTS_KEY, siteId]);
    },
  });
}

function useInvalidateQueryAll(siteId: number) {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries([UTILITY_ACCOUNTS_KEY, siteId]);
  };
}

function useQueryUtilityTypes(siteId: number, enabled = true) {
  return useQuery<AccountUtilityType[]>({
    queryKey: [UTILITY_ACCOUNTS_KEY, "utilityTypes", siteId],
    queryFn: async () => {
      const { data, error } = await FetchClients.utilities.GET(
        "/{site_id}/accounts/utility_types",
        {
          params: {
            path: {
              site_id: siteId,
            },
          },
        }
      );
      if (data) return data;
      throw new Error(error);
    },
    enabled,
  });
}
