import classNames from "classnames";

import Text from "@src/straps/base/type/Text/Text";
import styles from "./Tooltip.module.scss";
import TooltipWrapper from "@src/straps/utils/TooltipWrapper/TooltipWrapper";

type TooltipProps = Omit<
  React.ComponentPropsWithoutRef<typeof TooltipWrapper>,
  "tooltip"
> & {
  content: string | React.ReactNode;
  width?: number;
  maxHeight?: number;
  height?: number;
};

export default function Tooltip({
  width,
  height,
  content,
  maxHeight,
  ...tooltipProps
}: TooltipProps) {
  const align = tooltipProps.align;
  return (
    <TooltipWrapper
      {...tooltipProps}
      margin={12}
      tooltip={
        <div className="flex">
          <div className="relative inline-block rounded-sm bg-pure-white shadow-lg">
            <div
              className="overflow-auto px-4 py-2"
              style={{
                height: height ? height + "px" : "auto",
                width: width ? width + "px" : "auto",
                maxHeight: maxHeight ? maxHeight + "px" : "auto",
              }}
            >
              {typeof content === "string" ? (
                <Text variant="sb_t-12-500">{content}</Text>
              ) : (
                <div>{content}</div>
              )}
              <div
                className={classNames(styles.triangle, {
                  [styles.top!]: !align || align === "top",
                  [styles.topLeft!]: align === "topLeft",
                  [styles.topRight!]: align === "topRight",
                  [styles.bottom!]: align === "bottom",
                })}
              />
            </div>
          </div>
        </div>
      }
    />
  );
}
