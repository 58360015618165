import {
  useMutation,
  useQuery as useReactQuery,
  useQueryClient,
} from "react-query";
import { VITE_UPLOAD_BASE_URL } from "../../../env";
import { recursiveSnakeCaseCipher } from "../../postgrestApi";
import request from "../../request";
import { UTILITY_BILL_CONSUMPTION_KEY } from "../utilities/consumption";

const QUERY_KEY = "uploadable-bill";

const backpackBill = {
  useQuery,
  mutations: {
    usePost,
  },
} as const;

export default backpackBill;

// GET (S3 download url)

// Returns url to download bill
const getDownloadBackpackBill = (siteId: number, billId: number) =>
  request
    .get(`${VITE_UPLOAD_BASE_URL}/backpack_bill/download`)
    .query({
      site_id: siteId,
      bill_id: billId,
    })
    .then((res) => res.text);

function useQuery(siteId: number, billId?: number, enabled = true) {
  return useReactQuery({
    queryKey: [QUERY_KEY, siteId, billId],
    queryFn: () => getDownloadBackpackBill(siteId, billId!),
    enabled: billId !== undefined && enabled,
    staleTime: Infinity,
  });
}

// POST (upload file)

interface BackpackBillUploadRequest {
  filename: string;
  siteId: number;
  billId: number;
  file: File;
}

const postUploadBackpackBill = ({
  file,
  ...req
}: BackpackBillUploadRequest): Promise<void> =>
  request
    .post(`${VITE_UPLOAD_BASE_URL}/backpack_bill/upload`)
    .query(recursiveSnakeCaseCipher(req))
    .send(file)
    .then(() => {});

function usePost() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postUploadBackpackBill,
    onSuccess: async (_, { siteId, billId }) => {
      await queryClient.invalidateQueries([QUERY_KEY, siteId, billId]);
      // BE also updates utility bill entity
      await queryClient.invalidateQueries([
        UTILITY_BILL_CONSUMPTION_KEY,
        siteId,
      ]);
    },
  });
}
