import { useMutation, useQuery } from "react-query";
import FetchClients, { ClientOutput } from "../client";
import {
  recursiveCamelCaseCipher,
  recursiveSnakeCaseCipher,
} from "@src/global_functions/postgrestApi";
import { CamelCasedPropertiesDeep } from "type-fest";

import { isBackpackResponseErrorBody, BackpackServiceError } from "../errors";

type InviteDetails = CamelCasedPropertiesDeep<
  ClientOutput["email"]["InviteDetails"]
>;
export type AcceptInviteBody = CamelCasedPropertiesDeep<
  ClientOutput["email"]["AcceptInviteReq"]
>;

type ResendInviteBody = CamelCasedPropertiesDeep<
  ClientOutput["email"]["ResendInviteReq"]
>;

const invites = {
  useQueryInviteDetails,
  mutations: {
    useSendUserInvitation,
    useAcceptInvite,
  },
} as const;

export default invites;

export const INVITE_DETAILS_KEY = "/invite";

const getInviteDetails = async (invite_token?: string) => {
  if (!invite_token) throw new Error("No invite token provided");
  const { data, error } = await FetchClients.email.GET("/invite", {
    params: {
      query: { invite_token },
    },
  });
  if (data) return recursiveCamelCaseCipher(data) as InviteDetails;

  if (isBackpackResponseErrorBody(error)) {
    throw new BackpackServiceError(error);
  }

  throw new Error(
    `Failed to query invite token. ${
      (error as Error)?.message || "Unknown Error"
    }`
  );
};
function useQueryInviteDetails(invite_token?: string) {
  return useQuery([INVITE_DETAILS_KEY], () => getInviteDetails(invite_token), {
    enabled: !!invite_token,
  });
}

const acceptInvite = async (body: AcceptInviteBody) => {
  const { response, error } = await FetchClients.email.POST("/invite/accept", {
    body: recursiveSnakeCaseCipher(body),
  });
  if (response.ok) return;

  if (isBackpackResponseErrorBody(error)) {
    throw new BackpackServiceError(error);
  }

  throw new Error(
    `Failed to accept invite: ${
      (error as unknown as Error)?.message || "Unknown Error"
    }`
  );
};

function useAcceptInvite() {
  return useMutation({
    mutationFn: acceptInvite,
  });
}

const sendInvite = async (body: ResendInviteBody) => {
  const { response, error } = await FetchClients.email.POST("/invite/resend", {
    body: recursiveSnakeCaseCipher(body),
  });
  if (response.ok) return;
  throw new Error(
    `Failed to send invite: ${
      (error as unknown as Error)?.message || "Unknown Error"
    }`
  );
};

function useSendUserInvitation() {
  return useMutation({
    mutationFn: (userId: number) => sendInvite({ userId }),
  });
}
