import { bugsnagGeneralErrorHandler } from "@src/global_functions/postgrestApi";
import { useDownloadNotifier } from "@src/straps/derived/Notifier/DownloadNotifier";
import { getIdToken } from "@src/auth/utils";

import { startCase } from "lodash";
import { useCallback } from "react";
import { isDev, VITE_PORTABLE_DOCUMENT_FORMAT_BASE_URL } from "../../../env";
import { BackpackServiceError, isBackpackResponseErrorBody } from "../errors";

// These must match https://github.com/Bractlet/forty-two/blob/staging/portable-document-format/src/main.rs
const DOC_TYPES = {
  overview: "/print/property-overview-report", // redirect from /print/building-overview-report prevents breaking change with url in pdf service
  "environmental-impact": "/print/environmental-impact",
  "energy-star": "/print/energy-star-report",
  compliance: "/print/compliance-report",
  "equipment-summary": "/print/equipment",
  "site-equipment": "/print/equipment",
  utility: "/print/utility-overview-report",
} as const;

type DocType = keyof typeof DOC_TYPES;

const downloadDoc = async (
  type: DocType,
  filename: string,
  search: string,
  uriParams: string = ""
) => {
  if (isDev) {
    const previewUrl = `${DOC_TYPES[type]}${uriParams}?${search}`;
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        `DEV ONLY DIALOGUE: Click OK to preview ${filename}.pdf (${previewUrl}), or click Cancel to continue with download.`
      )
    ) {
      window.location.href = previewUrl;
      return;
    }
  }
  try {
    const token = await getIdToken();
    const res = await fetch(
      `${VITE_PORTABLE_DOCUMENT_FORMAT_BASE_URL}/doc/${type}${uriParams}?format=pdf&${search}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.ok) {
      const blob = await res.blob();
      const newBlob = new Blob([blob]);

      const blobUrl = window.URL.createObjectURL(newBlob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `${filename}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

      window.URL.revokeObjectURL(blobUrl);
    } else {
      const error = res.json();
      if (isBackpackResponseErrorBody(error)) {
        throw new BackpackServiceError(error);
      }
      throw new Error(`${res.status} status received while downloading pdf`);
    }
  } catch (e) {
    bugsnagGeneralErrorHandler(e);
    alert(
      "An error occurred while generating your pdf, please try again later."
    );
  }
};

// Triggers a download of the pdf after fetching. Passes along all current search params.
function useDownloadWithRequiredParams(
  type: DocType,
  filenameBase: string,
  commonParams?: Record<string, string>,
  uriParams: string = ""
) {
  const { queueDownload } = useDownloadNotifier();

  return useCallback(
    (additionalParams: {
      reportName: string;
      reportTitle: string;
      [key: string]: string;
    }) => {
      const searchParams = new URLSearchParams({
        ...Object.fromEntries(new URLSearchParams(window.location.search)),
        ...Object.fromEntries(new URLSearchParams(commonParams)),
        ...Object.fromEntries(new URLSearchParams(additionalParams)),
      });

      const filename = `${filenameBase}_${startCase(
        additionalParams.reportName
      ).replace(/\s/g, "")}`;

      if (queueDownload) {
        // Prefer using DownloadNotifier to track download, if available
        return queueDownload(
          {
            name: `${filename}.pdf`,
            icon: "download-pdf",
            estimatedTimeMs: 30000,
          },
          downloadDoc(type, filename, searchParams.toString(), uriParams)
        );
      } else {
        return downloadDoc(type, filename, searchParams.toString(), uriParams);
      }
    },
    [type, filenameBase, commonParams, queueDownload, uriParams]
  );
}

const doc = {
  useDownloadOverviewPdf: (accountId?: number) =>
    useDownloadWithRequiredParams("overview", "PropertyOverviewReport", {
      accountId: String(accountId),
    }),
  useDownloadEnergyStarPdf: (accountId?: number) =>
    useDownloadWithRequiredParams("energy-star", "EnergyStarReport", {
      accountId: String(accountId),
    }),
  useDownloadCompliancePdf: (accountId?: number) =>
    useDownloadWithRequiredParams("compliance", "ComplianceReport", {
      accountId: String(accountId),
    }),
  useDownloadEnvironmentalImpactPdf: (accountId?: number) =>
    useDownloadWithRequiredParams(
      "environmental-impact",
      "EnvironmentalImpact",
      {
        accountId: String(accountId),
      }
    ),
  useDownloadPortfolioEquipmentReport: (accountId?: number) =>
    useDownloadWithRequiredParams(
      "equipment-summary",
      "PortfolioEquipmentReport",
      {
        accountId: String(accountId),
      }
    ),
  useDownloadSiteEquipmentReport: (siteId: number) =>
    useDownloadWithRequiredParams(
      "site-equipment",
      "SiteEquipmentReport",
      {},
      `/${siteId}`
    ),
  useDownloadUtilityOverviewReport: (accountId?: number) =>
    useDownloadWithRequiredParams("utility", "UtilityOverviewReport", {
      accountId: String(accountId),
    }),
};

export default doc;
