import { useMutation, useQuery as useReactQuery } from "react-query";
import { VITE_SITE_SERVICE_BASE_URL } from "../../../env";
import { recursiveCamelCaseCipher } from "../../postgrestApi";
import request from "../../request";
import { Equipment, EquipmentCondition } from "./types";
import { useInvalidateEquipmentQueries } from "./useInvalidateEquipmentQueries";
import { useCurrentAccount } from "@src/backpack-console/CurrentAccountManager";

const equipment = {
  useQuery,
  usePutPrimaryPhoto,
  useAddToWatchlist,
  usePutEquipmentCondition,
};

export default equipment;

export const EQUIPMENT_DETAILS_QUERY_KEY = "equipment-details";

const getEquipment = (equipmentId: number): Promise<Equipment> => {
  return request
    .get(`${VITE_SITE_SERVICE_BASE_URL}/equipment/${equipmentId}`)
    .then((res) => recursiveCamelCaseCipher(res.body));
};

function useQuery(equipmentId: number | undefined) {
  return useReactQuery({
    queryKey: [EQUIPMENT_DETAILS_QUERY_KEY, equipmentId],
    queryFn: async () => {
      if (equipmentId === undefined) {
        throw new Error("Equipment Id is required");
      }
      return getEquipment(equipmentId);
    },
    enabled: typeof equipmentId === "number",
  });
}

const putPrimaryPhoto = (fileId: number) => {
  return request
    .put(`${VITE_SITE_SERVICE_BASE_URL}/equipment/set_primary/${fileId}`)
    .then((res) => recursiveCamelCaseCipher(res.body));
};

function usePutPrimaryPhoto(siteId: number, equipmentId: number) {
  const { accountId } = useCurrentAccount();
  const invalidateQueries = useInvalidateEquipmentQueries();
  return useMutation({
    mutationFn: async (fileId: number) => {
      return putPrimaryPhoto(fileId);
    },
    onSettled: async () => {
      return invalidateQueries({
        equipmentIds: [equipmentId],
        siteId,
        accountId,
      });
    },
  });
}

function addToWatchlist(equipmentId: number) {
  return request
    .put(`${VITE_SITE_SERVICE_BASE_URL}/equipment/watch_list/${equipmentId}`)
    .set("Content-Type", "application/json")
    .send("true");
}

function useAddToWatchlist(siteId: number, equipmentId: number) {
  const { accountId } = useCurrentAccount();
  const invalidateQueries = useInvalidateEquipmentQueries();
  return useMutation({
    mutationFn: async () => {
      return addToWatchlist(equipmentId);
    },
    onSettled: async () => {
      return invalidateQueries({
        equipmentIds: [equipmentId],
        siteId,
        accountId,
      });
    },
  });
}

function putEquipmentCondition(
  equipmentId: number,
  condition: EquipmentCondition
) {
  return request
    .put(`${VITE_SITE_SERVICE_BASE_URL}/equipment/condition/${equipmentId}`)
    .set("Content-Type", "application/json")
    .send({ equipment_condition: condition });
}

function usePutEquipmentCondition(equipmentId: number, siteId: number) {
  const { accountId } = useCurrentAccount();
  const invalidateQueries = useInvalidateEquipmentQueries();
  return useMutation({
    mutationFn: async (condition: EquipmentCondition) => {
      return putEquipmentCondition(equipmentId, condition);
    },
    onSettled: async () => {
      return invalidateQueries({
        equipmentIds: [equipmentId],
        siteId,
        accountId,
      });
    },
  });
}
