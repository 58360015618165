import { lazy, Suspense } from "react";
import { DocumentTitle } from "@src/backpack-console/components/DocumentTitle";
import { Loader } from "@src/global_components";
const SignInActions = lazy(() => import("./SignInActions"));

export default function SignIn({
  isCurrentUserError,
}: Readonly<{ isCurrentUserError: boolean }>) {
  return (
    <Suspense fallback={<Loader size={80} />}>
      <DocumentTitle title="Login" />
      <SignInActions isCurrentUserError={isCurrentUserError} />
    </Suspense>
  );
}
