import { useField } from "react-final-form";

export const useFieldWithErrors = (name: string, config?: any) => {
  const { input, meta } = useField(name, config);

  return {
    value: input.value,
    meta,
  };
};
