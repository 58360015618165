import { useMutation } from "react-query";
import * as R from "ramda";
import FetchClients, { ClientOutput } from "../client";
import { CamelCasedPropertiesDeep, SetNonNullable } from "type-fest";
import {
  recursiveSnakeCaseCipher,
  recursiveCamelCaseCipher,
} from "@src/global_functions/postgrestApi";
import { useCurrentUser } from "@src/context/UserContext";
import { BackpackServiceError, isBackpackResponseErrorBody } from "../errors";

type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export type CreateAndInviteUserBody = Partial<
  Nullable<CamelCasedPropertiesDeep<ClientOutput["auth"]["CreateUserReq"]>>
>;

export type CreateSuperAdminBody = CamelCasedPropertiesDeep<
  ClientOutput["auth"]["CreateBackpackAdminReq"]
>;
export type User = CamelCasedPropertiesDeep<ClientOutput["auth"]["User"]>;

export const SIGNED_IN_USER_DELETE_ATTEMPT_ERROR_MESSAGE =
  "Cannot delete currently signed in user.";

const users = {
  mutations: {
    useCreateAndInviteUser,
    useAddSuperAdmin,
    useDelete,
    useBulkDelete,
    useRequestPasswordReset,
  },
} as const;

export default users;

const hasRequiredFields = (
  body: CreateAndInviteUserBody
): body is Required<SetNonNullable<CreateAndInviteUserBody>> =>
  Object.values(body).every(
    (val) => typeof val !== "undefined" && val !== null
  );

const createAndInviteUser = async (body: CreateAndInviteUserBody) => {
  if (!hasRequiredFields(body))
    throw new Error("Missing required fields to create user.");
  const { data, error } = await FetchClients.auth.POST("/user/create", {
    body: recursiveSnakeCaseCipher(body),
  });
  if (data) return recursiveCamelCaseCipher(data) as User;

  if (isBackpackResponseErrorBody(error)) {
    throw new BackpackServiceError(error);
  }

  throw new Error(
    `Failed to create user: ${
      (error as unknown as Error)?.message || "Unknown Error"
    }`
  );
};

function useCreateAndInviteUser() {
  return useMutation({
    mutationFn: createAndInviteUser,
  });
}

const addSuperAdmin = async (body: CreateSuperAdminBody) => {
  const { data, error } = await FetchClients.auth.POST(
    "/user/create_backpack_admin",
    {
      body: recursiveSnakeCaseCipher(body),
    }
  );
  if (data) return recursiveCamelCaseCipher(data) as User;

  throw new Error(
    `Failed to create user: ${
      (error as unknown as Error)?.message || "Unknown Error"
    }`
  );
};
function useAddSuperAdmin() {
  return useMutation({
    mutationFn: addSuperAdmin,
  });
}

const deleteUser = async (user_id: number) => {
  const { response, error } = await FetchClients.auth.PATCH(
    "/user/delete_user",
    {
      params: {
        query: { user_id },
      },
    }
  );
  if (response.ok) return;
  throw new Error(
    `Failed to delete user ID ${user_id}: ${
      (error as unknown as Error)?.message || "Unknown Error"
    }`
  );
};

function useDelete() {
  const currentUser = useCurrentUser();
  return useMutation({
    mutationFn: (userId: number) => {
      if (currentUser.id === userId) {
        throw new Error(SIGNED_IN_USER_DELETE_ATTEMPT_ERROR_MESSAGE);
      }
      return deleteUser(userId);
    },
  });
}

function useBulkDelete() {
  const currentUser = useCurrentUser();
  return useMutation({
    mutationFn: async (userIds: number[]) => {
      if (currentUser.id && userIds.includes(currentUser.id)) {
        throw new Error(SIGNED_IN_USER_DELETE_ATTEMPT_ERROR_MESSAGE);
      }
      const results = await Promise.allSettled(
        userIds.map((userId) => deleteUser(userId))
      );

      const errors = results
        .filter(
          (result): result is PromiseRejectedResult =>
            result.status === "rejected"
        )
        .map(R.prop("reason"))
        .filter((error): error is Error => error instanceof Error);

      if (errors.length) {
        throw new Error(`${R.map(R.prop("message"), errors).join()}`);
      }
    },
  });
}

function useRequestPasswordReset() {
  return useMutation(async (email: string) => {
    const { response, error } = await FetchClients.auth.POST(
      "/user/reset_password",
      {
        body: {
          email,
        },
      }
    );
    if (response.ok) return;
    throw new Error(error);
  });
}
