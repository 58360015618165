import { cloneDeep } from "lodash";
import * as R from "ramda";

import type {
  AllUtilityCategories,
  AccountSummary,
  EnergyUtility,
  EnergyUtilityCategory,
  SystemCategory,
  TopLevelUtility,
  TopLevelUtilityCategory,
  Utility,
  WasteUtilityCategory,
  WaterUtility,
  WaterUtilityCategory,
} from "../types";

import {
  formatIncompletePhoneNumber,
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "libphonenumber-js";

export const sortedTopLevelUtilities: Array<TopLevelUtilityCategory> = [
  {
    id: "energy",
    title: "Energy",
    icon: "Energy",
    color: "#00cc8f",
    units: ["kWh", "GJ", "kBTU"],
    defaultUnit: "kWh",
  },
  {
    id: "water",
    title: "Water",
    icon: "WaterFaucet",
    color: "#57d2f2",
    units: ["gal", "CCF", "cGal", "kGal", "m3"],
    defaultUnit: "gal",
  },
  {
    id: "waste",
    title: "Waste",
    icon: "Waste",
    color: "#788ac3",
    units: ["yd3", "ton", "tonnes"],
    defaultUnit: "yd3",
  },
];

const energyUtilities: Array<EnergyUtility> = [
  "electricity",
  "naturalGas",
  "districtHeating",
  "districtCooling",
];

export const waterUtilities: Array<WaterUtility> = ["water", "wasteWater"];

export const getTopLevelUtility = (utility: Utility): TopLevelUtility =>
  // @ts-expect-error - TS2345 - Argument of type 'Utility' is not assignable to parameter of type 'EnergyUtility'.
  energyUtilities.includes(utility)
    ? "energy"
    : // @ts-expect-error - TS2345 - Argument of type 'Utility' is not assignable to parameter of type 'WaterUtility'.
    waterUtilities.includes(utility)
    ? "water"
    : "waste";

const sortedEnergyUtilities: Array<EnergyUtilityCategory> = [
  {
    id: "electricity",
    title: "Electricity",
    topLevelUtility: "energy",
    icon: "LightBulb",
    color: "#ffd630",
    colorName: "yellow",
    units: ["kWh", "kBTU", "GJ"],
    defaultUnit: "kWh",
  },
  {
    id: "naturalGas",
    title: "Natural Gas",
    topLevelUtility: "energy",
    icon: "Flame",
    color: "#ff7f3d",
    colorName: "orange-gas",
    units: [
      "kWh",
      "thm",
      "MBTU",
      "CCF",
      "GJ",
      "kBTU",
      "KCF",
      "MCF (million cubic feet)",
    ],
    defaultUnit: "kWh",
  },
  {
    id: "districtCooling",
    title: "District Cooling",
    topLevelUtility: "energy",
    icon: "WaterDroplet",
    color: "#416ce9",
    colorName: "blue",
    units: ["kWh", "ton-hour", "GJ", "kBTU"],
    defaultUnit: "kWh",
  },
  {
    id: "districtHeating",
    title: "District Heating",
    topLevelUtility: "energy",
    icon: "WaterDroplet",
    color: "#fb4e77",
    colorName: "red",
    units: ["kWh", "ton-hour", "GJ", "kBTU", "kLb"],
    defaultUnit: "kWh",
  },
];

const sortedWaterUtilities: Array<WaterUtilityCategory> = [
  {
    id: "water",
    title: "Water",
    icon: "WaterFaucet",
    topLevelUtility: "water",
    color: "#57d2f2",
    colorName: "bright-light-blue",
    units: ["gal", "CCF", "cGal", "kGal"],
    defaultUnit: "gal",
  },
  {
    id: "wasteWater",
    title: "Waste Water",
    topLevelUtility: "water",
    icon: "WaterFaucet",
    color: "#ff7f3d",
    colorName: "orange-gas",
    units: ["gal", "CCF", "cGal", "kGal"],
    defaultUnit: "gal",
  },
];

const sortedWasteUtilities: Array<WasteUtilityCategory> = [
  {
    id: "waste",
    title: "Waste",
    topLevelUtility: "waste",
    icon: "Waste",
    color: "#57d2f2",
    colorName: "bright-light-blue",
    units: ["yd3", "ton"],
    defaultUnit: "yd3",
  },
  // {
  //   id: "recycling",
  //   title: "Recycling",
  //   topLevelUtility: "waste",
  //   icon: "Waste",
  //   color: "#ff7f3d",
  //   units: ["yd3", "ton"],
  // },
];

export const sortedAllUtilities: Array<AllUtilityCategories> = [
  ...sortedEnergyUtilities,
  ...sortedWaterUtilities,
  ...sortedWasteUtilities,
];

export const topLevelUtilitiesById: Partial<
  Record<TopLevelUtility, TopLevelUtilityCategory>
> = R.indexBy(R.prop("id"), sortedTopLevelUtilities);

export const allUtilitiesById: Record<Utility, AllUtilityCategories> =
  R.indexBy(R.prop("id"), [
    ...sortedEnergyUtilities,
    ...sortedWaterUtilities,
    ...sortedWasteUtilities,
  ]);

const sortedSystems: Array<SystemCategory> = [
  {
    id: "lighting",
    system: "lighting",
    title: "Lighting",
    icon: "LightBulb",
    color: "#ffd630",
  },
  {
    id: "equipment",
    system: "equipment",
    title: "Equipment",
    icon: "Computer",
    color: "#00cc8f",
  },
  {
    id: "fans",
    system: "fans",
    title: "Fans",
    icon: "Fan",
    color: "#42a3ff",
  },
  {
    id: "pumps",
    system: "pumps",
    title: "Pumps",
    icon: "Pump",
    color: "#886cff",
  },
  {
    id: "cooling",
    system: "cooling",
    title: "Cooling",
    icon: "WaterDroplet",
    color: "#416ce9",
  },
  {
    id: "heating",
    system: "heating",
    title: "Heating",
    icon: "WaterDroplet",
    color: "#fb4e77",
  },
  {
    id: "heat-rejection",
    system: "heat-rejection",
    title: "Heat Rejection",
    icon: "HeatRejection",
    color: "#ff7f95",
  },
  {
    id: "domestic-hot-water",
    system: "domestic-hot-water",
    title: "Domestic Hot Water",
    icon: "WaterFaucet",
    color: "#fb4e77",
  },
  {
    id: "other",
    system: "other",
    title: "Other",
    icon: "Require",
    color: "#b9c3e1",
  },
];

export const systemsById = R.indexBy(R.prop("id"), sortedSystems);

export const getBuildings = (
  sites: AccountSummary["sites"],
  blackListIDs: any[]
): AccountSummary["sites"] => {
  const buildings = cloneDeep(sites).filter(
    (item) => !blackListIDs.includes(item.id)
  );
  return buildings || [];
};

export const parseRawNumbers = (value: string): number => {
  return parseInt(value.replace(/[^\d]/g, ""));
};

export const formatLocalStringInput = (value: string): string | null => {
  let parsedNum = parseRawNumbers(value);
  if (isNaN(parsedNum)) return null;

  return parsedNum.toLocaleString();
};

export const parseInteger = (value: string) => {
  const parsed = parseRawNumbers(value);
  if (Number.isNaN(parsed)) {
    return "";
  }
  return parsed;
};

export const formatNumberForSubmit = (value?: number | string | null) => {
  if (value) {
    return typeof value === "number" ? value : parseRawNumbers(value);
  }
  return null;
};

export const isValidPhoneNumber = (value?: string): boolean => {
  return Boolean(value) && isPossiblePhoneNumber(value || "", "US");
};

export const getPhoneNumberSubmissionValue = (
  value?: string
): string | null => {
  let submissionValue;
  try {
    submissionValue = parsePhoneNumber(value || "", "US").nationalNumber;
  } catch (e) {
    submissionValue = null;
  }
  return submissionValue;
};

export const formatPhoneNumber = (value?: string): string => {
  // could also use the parsePhoneNumer function from libphonenumber-js
  // but that function throws an error if the value is not a possible phone number
  // the below function handles the event that a bad number is somehow saved in the DB
  return formatIncompletePhoneNumber(value || "", "US");
};
