import {
  useQuery as useReactQuery,
  useMutation,
  useQueryClient,
} from "react-query";

import { camelCase, snakeCase } from "lodash";
import {
  recursiveCamelCaseCipher,
  recursiveSnakeCaseCipher,
} from "@src/global_functions/postgrestApi";
import request from "@src/global_functions/request";

const overviewPrimarySiteContacts = {
  useQuery,
  mutations: {
    usePost,
    useDelete,
  },
} as const;

export default overviewPrimarySiteContacts;

export const siteRoles = [
  "assetManager",
  "propertyManager",
  "assistantPropertyManager",
  "buildingEngineer",
] as const;
export type SiteRole = (typeof siteRoles)[number];

const SITE_ROLE_PRIMARY_CONTACT_KEY = "site-role-primary-contacts";
type SiteRolePrimaryContact = {
  firstName?: string;
  lastName?: string;
  email?: string;
  userId: number;
  siteRole: SiteRole;
  siteId: number;
};
interface SiteRolePrimaryContactRequest {
  // Has precedence over accountId
  siteId?: number;

  accountId?: number;
}
const getSiteRolePrimaryContacts = async (
  req: SiteRolePrimaryContactRequest
): Promise<SiteRolePrimaryContact[] | undefined> => {
  const query = req.siteId
    ? {
        site_id: `eq.${req.siteId}`,
      }
    : {
        account_id: `eq.${req.accountId}`,
      };

  if (req.accountId || req.siteId)
    return request
      .get(`/rest/v_site_role_primary_contacts_summary`)
      .query(query)
      .then(({ body }) => body.map(recursiveCamelCaseCipher));
};
function useQuery(req: SiteRolePrimaryContactRequest) {
  return useReactQuery(
    [SITE_ROLE_PRIMARY_CONTACT_KEY, req],
    () => getSiteRolePrimaryContacts(req),
    {
      select: (data) =>
        data?.map((contact) => ({
          ...contact,
          siteRole: camelCase(contact.siteRole) as (typeof siteRoles)[number],
        })),
      refetchOnWindowFocus: false,
    }
  );
}

type SiteRolePrimaryContactInput = Pick<
  SiteRolePrimaryContact,
  "siteId" | "siteRole" | "userId"
>;
const postSiteRolePrimaryContacts = (body: SiteRolePrimaryContactInput) =>
  request
    .post("/rest/site_role_primary_contacts")
    .set("Prefer", "resolution=merge-duplicates")
    .query({ on_conflict: "site_id,site_role" })
    .send({
      ...recursiveSnakeCaseCipher(body),
      site_role: snakeCase(body.siteRole),
    });
function usePost(siteId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postSiteRolePrimaryContacts,
    onSuccess: () => {
      queryClient.invalidateQueries([
        SITE_ROLE_PRIMARY_CONTACT_KEY,
        {
          siteId,
        },
      ]);
    },
  });
}

const deleteSiteRolePrimaryContacts = ({
  role,
  siteId,
}: {
  role: (typeof siteRoles)[number];
  siteId: number;
}) =>
  request
    .delete("/rest/site_role_primary_contacts")
    .query({ site_role: `eq.${snakeCase(role)}`, site_id: `eq.${siteId}` });

function useDelete(siteId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (role: (typeof siteRoles)[number]) =>
      deleteSiteRolePrimaryContacts({ role, siteId }),
    onSuccess: () => {
      queryClient.invalidateQueries([
        SITE_ROLE_PRIMARY_CONTACT_KEY,
        {
          siteId,
        },
      ]);
    },
  });
}
