import request from "../../../global_functions/request";

import { useMutation, useQueryClient } from "react-query";
import { ledgerKeys } from "./queryKeyFactory";
import type {
  BackpackFieldUpdateData,
  FIELD_ID_VALUES,
} from "../../../backpack-console/pages/Overview/types";
import { VITE_LEDGER_BASE_URL } from "../../../env";
import { isArray, isObject, snakeCase, transform } from "lodash";

const updateBackpackField = {
  mutations: {
    usePost,
  },
} as const;

export default updateBackpackField;

// @ts-expect-error - TS7006 - Parameter 'obj' implicitly has an 'any' type.
const snakeCaseKeys = (obj) =>
  transform(obj, (acc, value, key, target) => {
    // @ts-expect-error - TS2345 - Argument of type 'string | number | symbol' is not assignable to parameter of type 'string | undefined'.
    const snakeKey = isArray(target) ? key : snakeCase(key);
    // @ts-expect-error - TS2571 - Object is of type 'unknown'.
    acc[snakeKey] = isObject(value) ? snakeCaseKeys(value) : value;
  });

const postBackpackFieldUpdate = (
  siteId: string | number,
  data: BackpackFieldUpdateData,
  fieldId?: FIELD_ID_VALUES
) => {
  if (!fieldId) throw new Error("No field Id!");
  return (
    request
      .post(
        `${VITE_LEDGER_BASE_URL}/sites/${siteId}/fields/${fieldId}/update-backpack`
      )
      // @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'string | object | undefined'.
      .send(snakeCaseKeys(data))
  );
};

function usePost(siteId: number, fieldId?: FIELD_ID_VALUES) {
  const queryKey = ledgerKeys.field(siteId, fieldId);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: BackpackFieldUpdateData) =>
      postBackpackFieldUpdate(siteId, data, fieldId),
    onSuccess: async () => await queryClient.invalidateQueries(queryKey),
  });
}
