import type { SummaryOverview, SummaryOverviewQueryParams } from "../../types";
import { getSites, getSite } from "../../global_functions/postgrestApi";

export const SITE_SUMMARY_FIELDS: SummaryOverviewQueryParams = [
  "id",
  "accountId",
  "accountName",
  "gresbAssetId",
  "arcAssetId",
  "energyStarPropertyId",
  "productTier",
  "name",
  "imageKey",
  "status",
  "latitude",
  "longitude",
];

export const handleGetSites = (
  includeInactive = false,
  fields?: SummaryOverviewQueryParams
) =>
  getSites({
    includeInactive,
    fields,
  });

export async function handleRefreshSite(
  siteId: string | number,
  setSiteData: (
    arg1:
      | SummaryOverview[]
      | ((
          arg1?: SummaryOverview[] | null | undefined
        ) => SummaryOverview[] | null | undefined)
      | null
      | undefined
  ) => void,
  includeInactive = false,
  fields?: SummaryOverviewQueryParams
) {
  // Retrieve individual site
  const updatedSite = await getSite(siteId, {
    includeInactive,
    fields,
  });
  if (!updatedSite) {
    throw new Error(`Error refreshing Site (siteId: ${siteId})`);
  }

  // Update main sites array with new data
  setSiteData((allSites) => {
    let found = false;
    const updatedSites = (allSites ?? []).map((site) => {
      if (Number(site.id) === Number(siteId)) {
        found = true;
        return updatedSite;
      }
      return site;
    });

    if (updatedSite && !found) updatedSites.push(updatedSite);
    return updatedSites;
  });
}
