import { ValueOf } from "../utilTypes";

export const userRoles = [
  { id: "property_manager", label: "Property Manager" },
  { id: "asset_manager", label: "Asset Manager" },
  { id: "engineering", label: "Engineering" },
  { id: "sustainability", label: "Sustainability" },
] as const;

export const userRoleAdmin = "admin";
const userRoleEditor = "editor";
const userRoleReadOnly = "read_only";

export const userPermissions = [
  { id: userRoleAdmin, label: "Administrator" },
  { id: userRoleEditor, label: "Editor" },
  { id: userRoleReadOnly, label: "Read Only" },
] as const;

export const statesMap = {
  AK: "Alaska",
  AL: "Alabama",
  AR: "Arkansas",
  AS: "American Samoa",
  AZ: "Arizona",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DC: "District of Columbia",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  IA: "Iowa",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  MA: "Massachusetts",
  MD: "Maryland",
  ME: "Maine",
  MI: "Michigan",
  MN: "Minnesota",
  MO: "Missouri",
  MP: "Northern Mariana Islands",
  MS: "Mississippi",
  MT: "Montana",
  NC: "North Carolina",
  ND: "North Dakota",
  NE: "Nebraska",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NV: "Nevada",
  NY: "New York",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UM: "Minor Outlying Islands",
  UT: "Utah",
  VA: "Virginia",
  VI: "Virgin Islands",
  VT: "Vermont",
  WA: "Washington",
  WI: "Wisconsin",
  WV: "West Virginia",
  WY: "Wyoming",
} as const;

export const energyStarPropertyType = {
  "Bank Branch": 1,
  "Financial Office": 2,
  "Adult Education": 3,
  "College/University": 4,
  "K-12 School": 5,
  "Other – Education": 8,
  "Pre-school/Daycare": 6,
  "Vocational School": 7,
  Aquarium: 9,
  "Bar/Nightclub": 10,
  "Bowling Alley": 11,
  Casino: 12,
  "Convention Center": 13,
  "Fitness Center/Health Club/Gym": 14,
  "Ice/Curling Rink": 15,
  "Indoor Arena": 16,
  "Movie Theater": 17,
  Museum: 18,
  "Other – Entertainment/Public Assembly": 27,
  "Other – Recreation": 28,
  "Other – Stadium": 29,
  "Performing Arts": 19,
  "Race Track": 20,
  "Roller Rink": 21,
  "Social/Meeting Hall": 22,
  "Stadium (Closed)": 23,
  "Stadium (Open)": 24,
  "Swimming Pool": 25,
  Zoo: 26,
  "Convenience Store with Gas Station": 30,
  "Convenience Store without Gas Station": 31,
  "Fast Food Restaurant": 32,
  "Food Sales": 33,
  "Food Service": 34,
  "Other – Restaurant/Bar": 38,
  Restaurant: 35,
  "Supermarket/Grocery Store": 36,
  "Wholesale Club/Supercenter": 37,
  "Hospital (General Medical & Surgical)*": 39,
  "Medical Office": 40,
  "Other – Specialty Hospital": 45,
  "Outpatient Rehabilitation/Physical Therapy": 41,
  "Residential Care Facility": 42,
  "Senior Living Community": 43,
  "Urgent Care/Clinic/Other Outpatient": 44,
  Barracks: 46,
  Hotel: 47,
  "Multifamily Housing": 48,
  "Other – Lodging/Residential": 52,
  "Prison/Incarceration": 49,
  "Residence Hall/Dormitory": 50,
  "Single Family Home": 51,
  "Manufacturing/Industrial Plant": 53,
  "Mixed Use Property": 54,
  Office: 55,
  "Other – Office": 57,
  "Veterinary Office": 56,
  "Other – Other": 87,
  "Other – Public Service": 67,
  "Other – Retail/Mall": 74,
  "Other – Services": 80,
  "Other – Technology/Science": 77,
  "Other – Utility": 82,
  Parking: 58,
  Courthouse: 59,
  "Drinking Water Treatment & Distribution": 60,
  "Fire Station": 61,
  Library: 62,
  "Mailing Center/Post Office": 63,
  "Police Station": 64,
  "Transportation Terminal/Station": 65,
  "Wastewater Treatment Plant": 66,
  "Worship Facility": 68,
  "Automobile Dealership": 69,
  "Enclosed Mall": 70,
  "Lifestyle Center": 71,
  "Retail Store": 72,
  "Strip Mall": 73,
  "Data Center": 75,
  "Personal Services (Health/Beauty, Dry Cleaning, etc)": 78,
  "Repair Services (Vehicle, Shoe, Locksmith, etc)": 79,
  Laboratory: 76,
  "Energy/Power Station": 81,
  "Distribution Center": 84,
  "Non-Refrigerated Warehouse": 85,
  "Refrigerated Warehouse": 86,
  "Self-Storage Facility": 83,
} as const;

export const IntegrationPartnerEnum = {
  Backpack: { sourceId: 1, label: "User Input" },
  EnergyStar: { sourceId: 2, label: "Energy Star" },
} as const;
export type LedgerSourceId = ValueOf<typeof IntegrationPartnerEnum>["sourceId"];
